import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Documentation, CODE_RESPONSABLE } from '../../model/model';
import { DialogMailToComponent } from '../dialog-mail-to/dialog-mail-to.component';
import { DialogVideComponent } from '../dialog-vide/dialog-vide.component';

@Component({
  selector: 'app-liens',
  templateUrl: './liens.component.html',
  styleUrls: ['./liens.component.scss']
})
export class LiensComponent {

  /** Documentation du service ou de la direction */
  @Input() documentations: Documentation[];

  @Input() nom: string;

  /** Constructeur pour l'injection des dépendances */
  constructor(private dialog: MatDialog) { }

  /** Ouvre une pop-up avec le nom des destinataires associés à la direction */
  ouvrirDestinataires() {
    const responsables = this.documentations.filter((doc) => doc.categorie === CODE_RESPONSABLE);
    if (!this.documentations || responsables.length === 0) {
      this.dialog.open(DialogVideComponent, {
        data: 'Aucun interlocuteur ' + this.nom + ' trouvé',
        maxHeight: '80%',
      });
    } else {
      this.dialog.open(DialogMailToComponent, {
        data: { documentations: responsables, nomDirection: this.nom },
        maxHeight: '80%',
      });
    }
  }
}
