import { Component } from '@angular/core';
import { DialogGraphStatsDirectionsComponent } from '../dialog-graph-stats-directions/dialog-graph-stats-directions.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bouton-graph-stats',
  templateUrl: './bouton-graph-stats.component.html',
  styleUrls: ['./bouton-graph-stats.component.scss']
})
export class BoutonGraphStatsComponent {

  constructor(private dialogStats: MatDialog) { }

  ouvrirGraph() {
    this.dialogStats.open(DialogGraphStatsDirectionsComponent, {
      width: '95vw',
      maxHeight: '95vh'
    });
  }

}
