import { Injectable, ElementRef } from "@angular/core";
import { Chart, ChartConfiguration, ChartTypeRegistry } from "chart.js";
// plugin pour afficher les valeurs en %
import "chartjs-plugin-labels";
import "chartjs-plugin-doughnutlabel";
import { DataGraphique } from "../../model/model";
import { MOIS } from "../date.adapter";

@Injectable({
  providedIn: "root",
})
export class GraphUtilsService {
  private static readonly chartTypes: (keyof ChartTypeRegistry)[] = [
    "bar",
    "line",
    "pie",
    "doughnut",
    "polarArea",
    "radar",
    "bubble",
    "scatter",
    // Add any other chart types you need
  ];

  /** couleurs rouge, orange, verte pour les statuts */
  colorsIndispo: string[] = ["#f44336", "#ffc107", "#8bc34a"];
  /** couleurs quand pas statuts */
  colors: string[] = [
    "#ff867c",
    "#b085f5",
    "#80d6ff",
    "#98ee99",
    "#ffff8b",
    "#ffa270",
    "#be9c91",
    "#efefef",
    "#a7c0cd",
  ];
  /** couleurs foncées quand pas statuts */
  colorsFonce: string[] = [
    "#ef5350",
    "#7e57c2",
    "#42a5f5",
    "#66bb6a",
    "#ffee58",
    "#ff7043",
    "#8d6e63",
    "#bdbdbd",
    "#78909c",
  ];

  // Type guard function to check if a given type is a valid chart type

  private isChartType(
    type: string
  ): type is Extract<keyof ChartTypeRegistry, string> {
    return GraphUtilsService.chartTypes.includes(
      type as keyof ChartTypeRegistry
    );
  }
  /**
   *
   * Creer la base d'un graph
   *
   * @param data données du graph
   * @param titre titre du graphique
   * @param graph elementRef du graph
   * @param type type du graph
   * @param showLegend affichage de la légende
   */
  private creerGraph(
    data: any,
    titre: string,
    graph: ElementRef,
    type: string,
    showLegend: boolean = false
  ): any {
    if (!this.isChartType(type)) {
      throw new Error(`Invalid chart type: ${type}`);
    }

    return new Chart(graph.nativeElement.getContext("2d"), {
      type: type,
      data: data,
      options: {
        legend: {
          display: false,
        },
        plugins: {
          title: {
            display: !!titre,
            text: titre,
            font: {
              size: 16,
            },
          },
        },
        animation: {
          duration: 2000,
        },
        responsive: true,
      },
    });
  }

  /**
   * Chargement du graphique avec les données récupérées
   *
   * @param data données du graph
   * @param titre titre du graphique
   * @param graph elementRef du graph
   * @param showLegend affichage de la légende
   */
  creerGraphBar(
    data: any,
    titre: string,
    graph: ElementRef,
    showLegend: boolean = false
  ): any {
    return this.ajouterSpecificiteesBar(
      this.creerGraph(data, titre, graph, "bar", showLegend)
    );
  }

  /**
   * Ajoute les paramètres particuliers pour le graph en bar
   *
   * @param graphique
   */
  private ajouterSpecificiteesBar(graphique: any): any {
    // Transparent pour pas afficher
    graphique.config.options.plugins = {
      labels: { fontColor: "#00000000" },
    };
    graphique.config.options = {
      legend: {
        display: false,
      },
    };

    graphique.chart.options.scales = {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false,
          },
        },
      ],
    };
    return graphique;
  }

  /**
   * Génration d'un graph 'donunt'
   *
   * @param data données du graph
   * @param titre titre du graphique
   * @param graphContext context du graph
   * @param graph elementRef du graph
   * @param showLegend affichage de la légende
   */
  creerGraphDoughnut(
    data: any,
    titre: string,
    graph: ElementRef,
    showLegend: boolean = false
  ): any {
    return this.ajouterSpecificiteesCirculaire(
      this.creerGraph(data, titre, graph, "doughnut", showLegend)
    );
  }

  /**
   * Ajoute les paramètres particuliers pour le graph circu
   *
   * @param graphique
   */
  private ajouterSpecificiteesCirculaire(graphique: any): any {
    graphique.chart.options.scales = {};
    graphique.config.options.plugins = {
      labels: {
        render: "percentage",
        precision: 0,
        fontColor: "#000",
        overlap: false,
      },
    };
    graphique.config.options = {
      legend: {
        display: false,
      },
    };

    graphique.update();
    return graphique;
  }

  /**
   * ajout des datas pour un graph
   *
   * Les maps doivent toutes avoir le même nombre de clefs
   *
   * @param donnees liste d'objet DataGraphique contenant une map avec les donnees (valeurs/légendes) et un titre par donnees
   * @param isIndispo true pour avoir les couleurs des statuts
   */
  creerDataGraph(donnees: DataGraphique[], isIndispo: boolean = false): any {
    if (!donnees || donnees.length === 0 || !donnees[0].mapData) {
      return [];
    }
    const dataSets: any[] = [];
    donnees.forEach((d, i) =>
      dataSets.push(this.ajouterDataSet(d, isIndispo, i))
    );

    return {
      datasets: dataSets,
      labels: Array.from(donnees[0].mapData.keys()),
    };
  }

  /** réinitialise les valeurs du graph avec de nouvelles */
  resetDataGraph(
    graph: any,
    donnees: DataGraphique[],
    isIndispo: boolean = false
  ): any {
    graph.chart.data = this.creerDataGraph(donnees, isIndispo);
    graph.update();
    return graph;
  }

  /**
   * ajout d'un dataset
   * @param donnees liste d'objet DataGraphique contenant une map avec les donnees (valeurs/légendes) et un titre par donnees
   * @param isIndispo true pour avoir les couleurs des statuts
   * @param index
   */
  private ajouterDataSet(
    donnees: DataGraphique,
    isIndispo: boolean = false,
    index: number = 0
  ): any {
    const dataSet: any = {
      data: Array.from(donnees.mapData.values()),
      label: donnees.nomData,
    };
    //  couleurs pour les histogramme d'indispo
    if (MOIS.includes(Array.from(donnees.mapData.keys())[0])) {
      dataSet.backgroundColor = this.colorsIndispo[index];
      // couleurs pour les autres graph d'indispo
    } else if (isIndispo) {
      dataSet.backgroundColor = this.colorsIndispo;
      // couleur pour les histo
    } else if (dataSet.data.length <= 2) {
      dataSet.backgroundColor = [this.colorsFonce[index], this.colors[index]];
      // couleur diagramme circu (pair/impair pour alterner)
    } else if (index / 2 === Math.round(index / 2)) {
      dataSet.backgroundColor = this.colorsFonce;
      // couleur diagramme circu
    } else {
      dataSet.backgroundColor = this.colors;
    }

    return dataSet;
  }

  /**
   * Ajoute un dataSet à partir d'un objet DataGraphique quand le graph est déjà chargé
   * @param donnees
   * @param graph
   * @param isIndispo true pour avoir les couleurs des statuts
   */
  ajouterData(
    donnees: DataGraphique[],
    graph: any,
    isIndispo: boolean = false
  ): void {
    if (graph.chart.config.type === "bar") {
      graph.chart.data = this.creerDataGraph(
        this.convertirDonneesCircuToHisto(donnees)
      );
    } else {
      // on garde la dernière entrée pour l'anim
      graph.data.datasets.push(
        this.ajouterDataSet(
          donnees.reduce((d1, d2) => d2),
          isIndispo,
          graph.data.datasets.length
        )
      );
    }
    graph.update();
  }

  /**
   * Converti un graph circulaire en histogramme (et vice-verca)
   * @param donnees données à incerer
   * @param graphique graphique
   */
  convertirGrahique(donnees: DataGraphique[], graphique: any) {
    // Changement de type du graph
    if (graphique.chart.config.type === "bar") {
      // Ajout du nouveau format de données
      graphique.chart.data = this.creerDataGraph(donnees);
      graphique.chart.config.type = "doughnut";
      this.ajouterSpecificiteesCirculaire(graphique);
    } else {
      // Ajout du nouveau format de données
      graphique.chart.data = this.creerDataGraph(
        this.convertirDonneesCircuToHisto(donnees)
      );
      graphique.chart.config.type = "bar";
      this.ajouterSpecificiteesBar(graphique);
    }
    graphique.update();
  }

  /**
   * Convertit les data d'un graph circulaire en histogramme (et vice-verca)
   * @param donnees données à incerer
   */
  convertirDonneesCircuToHisto(donnees: DataGraphique[]): DataGraphique[] {
    // Stock l'ancienne liste et on vide l'actuelle
    const anciennesDonnees: DataGraphique[] = donnees;
    donnees = [];
    // On boucle sur les clefs de la map du premier élément de la liste et on ajoutte la cle comme titre de la datat
    const clefs = Array.from(anciennesDonnees[0].mapData.keys());
    clefs.forEach((value) => donnees.push(new DataGraphique(new Map(), value)));
    // On boucle sur chaque map de chaque element du tableau
    anciennesDonnees.forEach((value) => {
      value.mapData.forEach((v, k) => {
        // On transfère l'ancien titre dans la clef de la map et on lui mets la valeur correspondante
        donnees.find((d) => d.nomData === k).mapData.set(value.nomData, v);
      });
    });
    return donnees;
  }
}
