import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Documentation } from '../../model/model';

@Component({
  selector: 'app-dialog-mail-to',
  templateUrl: './dialog-mail-to.component.html',
  styleUrls: ['./dialog-mail-to.component.scss']
})
export class DialogMailToComponent implements OnInit {

  documentations: Documentation[];
  nom: string;

  constructor(private dialogRef: MatDialogRef<DialogMailToComponent>,
    @Inject(MAT_DIALOG_DATA) private doc: { documentations: Documentation[], nomDirection: string }) { }

  ngOnInit() {
    this.documentations = this.doc.documentations;
    this.documentations.sort((d1, d2) => (d1.libelle > d2.libelle ? 1
      : (d1.libelle === d2.libelle ? (d1.information > d2.information ? 1 : -1)
        : -1)));
    this.nom = this.doc.nomDirection;
  }

  /** Ferme la dialog */
  fermer() {
    this.dialogRef.close();
  }

  /** Ouvre un onglet de rédaction de mail et ferme la pop-up */
  envoyerMail() {
    window.open('https://mail.google.com/mail/?view=cm&fs=1&to='
      + this.documentations.map(d => this.filtrerLeNomEtMail(d.information)).join(';'), '_blank');
    this.fermer();
  }
  envoyerMailUnique(destinataire: string) {
    window.open('https://mail.google.com/mail/?view=cm&fs=1&to=' + this.filtrerLeNomEtMail(destinataire), '_blank');
    this.fermer();
  }

  /** permet de gérer le cas "nom|email" */
  filtrerLeNomEtMail(nomEtMail: string) {
    if (nomEtMail.indexOf('|') !== -1) {
      return nomEtMail.split('|')[1];
    } else if (nomEtMail.indexOf('@') !== -1) {
      return nomEtMail;
    } else {
      return '';
    }
  }
}
