import { Component, Input, OnInit, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Service, CODE_FONCTION } from '../../model/model';

@Component({
    selector: 'app-fiche-service',
    templateUrl: './fiche-service.component.html',
    styleUrls: ['./fiche-service.component.scss']
})
export class FicheServiceComponent implements OnInit, OnChanges {

    @Input() serviceAvecDoc: Service;

    domaines: string[];
    description: string;
    fonctions: string[];

    ngOnInit() {
        // Sépare les infos contenu dans le champs description (domaine/description)
        const infosService: string[] = this.serviceAvecDoc.description.split('//');
        // Récupère les domaines en supprimé les éléments vides
        this.domaines = infosService[0].split(';').filter(d => d.trim().length !== 0);
        // Récupère le champ description
        this.description = infosService[1] ? infosService[1].trim() : null;
        this.fonctions = this.serviceAvecDoc.documentations
            .filter((doc) => doc.categorie === CODE_FONCTION)
            .map(d => d.information);
    }

    /**
       * S'execute quand la valeur de l'input change
       * Permet de raffraichir les données du composant lorsque le service est changé via la barre de recherche
       */
    ngOnChanges(changes: SimpleChanges) {
        for (const change in changes) {
            if (changes[change] instanceof SimpleChange && change === 'serviceAvecDoc' && changes[change].currentValue
                && !changes[change].isFirstChange()) {
                this.ngOnInit();
            }
        }
    }
}
