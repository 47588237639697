<h2 mat-dialog-title>
  <span>Historique d'activité pour {{(data.nom ? data.nom : "") | titlecase}}</span>
  <button mat-icon-button [mat-dialog-close] class="close">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<div id="dialogListeIndispo">
  <mat-dialog-content>
    <!-- Table historique des dernières indisponibilités créées -->
    <mat-table [dataSource]="data.liste">

      <!-- Créateur de l'indispo -->
      <ng-container cdkColumnDef="createur">
        <mat-header-cell *cdkHeaderCellDef>Créateur</mat-header-cell>
        <mat-cell *cdkCellDef="let indispo">{{indispo.utilisateur.prenom}} {{indispo.utilisateur.nom}}</mat-cell>
      </ng-container>

      <!-- Date de début de l'indispo -->
      <ng-container cdkColumnDef="dateDebut">
        <mat-header-cell *cdkHeaderCellDef>Date de début</mat-header-cell>
        <mat-cell *cdkCellDef="let indispo">{{indispo.dateDebut | date:'dd/MM/yyyy'}}</mat-cell>
      </ng-container>

      <!-- Date de fin de l'indispo -->
      <ng-container cdkColumnDef="dateFin">
        <mat-header-cell *cdkHeaderCellDef>Date de fin</mat-header-cell>
        <mat-cell *cdkCellDef="let indispo">{{indispo.dateFin | date:'dd/MM/yyyy'}}</mat-cell>
      </ng-container>

      <!-- Statut de l'indispo -->
      <ng-container cdkColumnDef="statut">
        <mat-header-cell *cdkHeaderCellDef>Statut</mat-header-cell>
        <mat-cell *cdkCellDef="let indispo">
          <img [src]=recupererImageMeteo(indispo.statut)>
        </mat-cell>
      </ng-container>

      <!-- Commentaire de l'indispo -->
      <ng-container cdkColumnDef="commentaire">
        <mat-header-cell *cdkHeaderCellDef>Commentaire</mat-header-cell>
        <mat-cell *cdkCellDef="let indispo" [matTooltip]="indispo.commentaire">
          {{ (indispo.commentaire.length > 30) ? (indispo.commentaire | slice:0:30) + '...' : (indispo.commentaire) }}
        </mat-cell>
      </ng-container>

      <!-- Actions (duplication ou envois) -->
      <ng-container cdkColumnDef="actions">
        <mat-header-cell *cdkHeaderCellDef style="max-width: 100px;">Actions</mat-header-cell>
        <mat-cell *cdkCellDef="let indispo" style="max-width: 100px;">
          <button mat-icon-button [mat-dialog-close]="{indispo: indispo, aDiffuser: false}">
            <mat-icon matTooltip="Dupliquer">filter_none</mat-icon>
          </button>
          <button mat-icon-button *ngIf="!indispo.dateDemandeDiffusion"
            [mat-dialog-close]="{indispo: indispo, aDiffuser: true}">
            <mat-icon matTooltip="Diffuser">send</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="listeDesColonnes; sticky: true"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: listeDesColonnes;"></mat-row>

    </mat-table>
  </mat-dialog-content>
</div>