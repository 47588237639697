<mat-card>
  <!-- titre de la carte-->
  <mat-card-subtitle class="ion-text-center">
    <mat-icon>notes</mat-icon>FICHE FONCTIONNELLE
  </mat-card-subtitle>
  <mat-card-content>
    <!-- Information sur le service -->
    <div text-justify>L'application <b>{{serviceAvecDoc.nom}}</b> ({{serviceAvecDoc.trigramme}}) fait partie du domaine<b
        *ngFor="let domaine of domaines">
        {{' ' + domaine | lowercase}}</b>.</div>
    <br />
    <div text-justify *ngIf="description">{{description}}.</div>
    <ul>
      <li *ngFor="let fonction of fonctions" text-justify>{{fonction}}</li>
    </ul>
  </mat-card-content>
</mat-card>