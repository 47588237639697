import { Component, OnInit } from '@angular/core';
import { DonneesEnCours } from '../../services/donnees-en-cours';
import { ReferentielService } from '../../services/referentiel.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCartographieComponent } from '../dialog-cartographie/dialog-cartographie.component';
import { Direction } from '../../model/model';

@Component({
  selector: 'app-cartographie',
  templateUrl: './cartographie.component.html',
  styleUrls: ['./cartographie.component.scss']
})

export class CartographieComponent implements OnInit {

  /** Donnees de l'image */
  donneesImage: Blob;

  /** Direction sélectionnée */
  direction: Direction;

  /** Constructeur pour l'injection des dépendances */
  constructor(private dialog: MatDialog, private donneesEnCours: DonneesEnCours, private referentielService: ReferentielService) { }

  /** Au chargement du composant */
  ngOnInit() {
    this.donneesEnCours.directionEnCours.subscribe((d) => {
      this.direction = d;
      this.donneesImage = undefined;
      this.referentielService.recupererCartoDirection(d.reference).subscribe((fluxImage) => {
        this.donneesImage = fluxImage;
      });
    });
  }

  /** Ouverture de l'image en popup */
  ouvrirImage() {
    this.dialog.open(DialogCartographieComponent, {
      maxHeight: '90vh',
      maxWidth: '90vw',
      data: this.donneesImage
    });
  }
}
