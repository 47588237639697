import { Component, OnInit, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AvisDto } from '../../model/model';
import { ParametreUtilisateurService } from '../../services/parametre-utilisateur.service';

/** Enum des couleur disponible pour les étoiles */
export enum StarRatingColor {
  primary = 'primary',  // Bleu
  accent = 'accent',    // Jaune
  warn = 'warn'         // Rouge
}

@Component({
  selector: 'app-notation',
  templateUrl: './notation.component.html',
  styleUrls: ['./notation.component.scss'],
})
export class NotationComponent implements OnInit {

  /** AvisDto */
  @Input() public avis: AvisDto;
  /** Nombre d'étoile */
  @Input() private noteMax = 5;
  /** Couleur des étoiles actives */
  @Input() private couleur: StarRatingColor = StarRatingColor.primary;
  /** Map des libelles des type de note */
  typeNoteMap = new Map([['ERGONOMIE', 'Ergonomie'], ['RAPIDITE', 'Rapidité d\'accès'], ['FORMATION', 'Formation satisfaisante ?']]);

  tableauNotes = [];

  constructor(private parametreUtilisateurService: ParametreUtilisateurService) { }

  ngOnInit() {
    // Initialisation du tableau avec l'ensemble des notes disponible en fonction de la noteMax
    for (let index = 0; index < this.noteMax; index++) {
      this.tableauNotes.push(index);
    }
  }

  /** Sélectionner note */
  selectionnerNote(note: number) {
    // Changement du style des étoiles en fonctione de la note sélectionnée
    this.avis.note = note;
    // Enregistrement de la note
    this.parametreUtilisateurService.sauvegarderAvis(this.avis).subscribe(avisDtoReponse => this.avis = avisDtoReponse);
  }

}
