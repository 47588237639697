import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RestUtilsService } from "./utilitaires/rest-utils.service";
import { Utilisateur } from "../model/model";
import { environment } from "../../environments/environment";
import { first } from "rxjs/operators";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SecuriteService {
  constructor(private http: HttpClient, private restUtils: RestUtilsService) {}

  url = environment.url + "/utilisateur";

  /** Chargement des infos de l'utilisateur */
  connexionUtilisateur(): Observable<Utilisateur> {
    const urlRecupUtilisateur = this.url + "/moi";
    return this.http
      .get<Utilisateur>(
        urlRecupUtilisateur,
        this.restUtils.creerHeaderPostWithAccessToken()
      )
      .pipe(
        first((r) => {
          return true;
        })
      );
  }
}
