import { Injectable } from "@angular/core";
import { PopupBlockedComponent } from "../../popup-blocked/popup-blocked.component";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { GoogleAuthProvider } from "@angular/fire/auth";
import { from, Observable, of, throwError } from "rxjs";
import { mergeMap, catchError, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Utilisateur } from "../../model/model";

@Injectable({ providedIn: "root" })
export class AuthentificationService {
  /**
   * vérifie si le token est valide et le user authentifié
   */
  constructor(
    private angularFireAuth: AngularFireAuth,
    private popupblocked: PopupBlockedComponent
  ) {}

  /** méthode qui permet de récupéré les token suite à la redirection */
  public chargerToken(): Observable<void> {
    return from(this.angularFireAuth.getRedirectResult()).pipe(
      tap((authGoogle: any) => {
        if (!authGoogle || !authGoogle.credential) {
          return;
        }
        // on sauvegarde les tokens
        this.setIdToken(authGoogle.credential.idToken);
        this.setAccessToken(authGoogle.credential.accessToken);
        this.setDateExpiration();
      })
    );
  }

  /**
   * vérifie si le token est valide et le user authentifié
   */
  public estConnecte(): Observable<boolean> {
    return this.angularFireAuth.authState.pipe(
      map((user: any) => {
        // si le user ne s'est jamais co sur la session
        if (
          !user ||
          !user.toJSON() ||
          !user.toJSON().stsTokenManager ||
          !user.toJSON().stsTokenManager.expirationTime
        ) {
          return false;
        }
        // on récupère la date d'expiration du token en milisecondes
        const dateExpiration: number =
          user.toJSON().stsTokenManager.expirationTime;
        const dureeAvantExpiration: number =
          dateExpiration - new Date().getTime();

        // si le token n'est pas expiré mais expire dans moins de 15 min

        if (0 < dureeAvantExpiration && dureeAvantExpiration < 15 * 60 * 1000) {
          // on recharge l'utilisateur et on le reconnecte (pas réussi à refresh l'acces token)
          this.supprimerToken();
          return false;

          // si le token est expiré
        } else if (dureeAvantExpiration <= 0) {
          this.supprimerToken();
          return false;
          // si le token est encore valide
        } else if (
          // si date expé null alors que token normalement valide
          // on supprime les token pour forcer la reco
          !this.getDateExpiration() ||
          // si date expé enregistrée est dépassée
          new Date().getTime() > +this.getDateExpiration() ||
          // si date d'expé ne correspond pas à la vrai date d'expé
          dateExpiration - +this.getDateExpiration() > 3 * 1000 * 60
        ) {
          this.supprimerToken();
          return false;
        } else {
          // on vérifie que les token son renseignés
          return !!this.getAccessToken() && !!this.getIdToken();
        }
      })
    );
  }

  public getAccessToken(): string {
    // return localStorage.getItem('ACCESS_TOKEN');
    return localStorage.getItem("ACCESS_TOKEN");
  }

  public getIdToken(): string {
    return localStorage.getItem("TOKEN");
  }

  public getToken(): Observable<void> {
    return from(this.angularFireAuth.getRedirectResult()).pipe(
      map((authGoogle: any) => {
        if (
          !authGoogle ||
          !authGoogle.credential ||
          !localStorage ||
          (this.getIdToken() === authGoogle.credential.idToken &&
            this.getAccessToken() === authGoogle.credential.accessToken)
        ) {
          return;
        }
        this.supprimerToken();
        // on sauvegarde les tokens
        this.setIdToken(authGoogle.credential.idToken);
        this.setAccessToken(authGoogle.credential.accessToken);
      })
    );
  }

  /**
   * récupère les infos manquantes du back
   */
  public getUtilisateur(): Observable<Utilisateur> {
    return this.angularFireAuth.user.pipe(
      map((u) => {
        const utilisateur = new Utilisateur();
        const splitNom: string[] = u.displayName.split(" ");
        utilisateur.prenom = splitNom.length > 1 ? splitNom[0] : "";
        utilisateur.nom = splitNom.length > 1 ? splitNom[1] : splitNom[0];
        utilisateur.image = u.photoURL;
        return utilisateur;
      })
    );
  }

  /**
   * ouvre un pop de connexion pour l'utilisateur
   */
  public seConnecter(): Observable<void> {
    // on configure les scopes sur le provider
    const provider: GoogleAuthProvider = new GoogleAuthProvider();
    provider.addScope("email");
    provider.addScope("profile");
    provider.addScope("openid");
    provider.setCustomParameters({
      hd: "veolia.com",
      redirect_uri: environment.redirectUri,
      display: "page",
    });

    // on ouvre un popup pour authentifier
    return from(this.angularFireAuth.signInWithPopup(provider)).pipe(
      mergeMap((authGoogle: any) => {
        if (
          !authGoogle ||
          !authGoogle.credential ||
          !localStorage ||
          (this.getIdToken() === authGoogle.credential.idToken &&
            this.getAccessToken() === authGoogle.credential.accessToken)
        ) {
          return of();
        }

        this.supprimerToken();

        // on sauvegarde les tokens
        this.setIdToken(authGoogle.credential.idToken);
        this.setAccessToken(authGoogle.credential.accessToken);
        this.setDateExpiration();

        location.reload();

        return of(authGoogle); // Retourne la valeur original à utilisé si nécessaire
      }),
      catchError((error) => {
        if (error.code === "auth/popup-blocked") {
          this.popupblocked.openDialog();
        } else {
          return throwError(error);
        }
      })
    );
  }

  public supprimerToken(): void {
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("DATE_EXPE");
  }

  private getDateExpiration(): string {
    return localStorage.getItem("DATE_EXPE");
  }

  private setAccessToken(token?: string): void {
    localStorage.setItem("ACCESS_TOKEN", token);
  }

  /**
   * sauvegarde la date d'expiration du token à partir de la date de connexion
   */
  private setDateExpiration(): void {
    // on ajoute 58 min pour être sûr de ne pas dépassé la véritable date d'expé
    localStorage.setItem(
      "DATE_EXPE",
      (new Date().getTime() + 58 * 60 * 1000).toString()
    );
  }

  private setIdToken(token?: string): void {
    localStorage.setItem("TOKEN", token);
  }
}
