export const environment = {
  production: true,
  url: 'https://meteodesservices-rest-dot-fr-water-vedif-meteo-prd.appspot.com/api',

  // https://developers.google.com/identity/sign-in/web/sign-in?refresh=1
  client_id: '515556355171-sieob8c05l6cgfeukn3ad3ouq0pqc7l4.apps.googleusercontent.com',

  // URL de redirection post connexion
  redirectUri: 'https://' + window.location.hostname,

  // config firebase
  firebaseConfig: {
    apiKey: 'AIzaSyAt6Ct2ArSbtiBAJZ2IbANhOcUtG3dpDpY',
    authDomain: 'fr-water-vedif-meteo-prd.firebaseapp.com',
    databaseURL: 'https://fr-water-vedif-meteo-prd.firebaseio.com',
    projectId: 'fr-water-vedif-meteo-prd',
    storageBucket: 'fr-water-vedif-meteo-prd.appspot.com',
    messagingSenderId: '515556355171',
    appId: '1:515556355171:web:cc4870ac9aac4ae1'
  }
};
