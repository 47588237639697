import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GraphUtilsService } from '../../services/utilitaires/graph-utils.service';
import { ParametreUtilisateurService } from '../../services/parametre-utilisateur.service';
import { DataGraphique } from '../../model/model';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-dialog-graph-stats-directions',
  templateUrl: './dialog-graph-stats-directions.component.html',
  styleUrls: ['./dialog-graph-stats-directions.component.scss']
})
export class DialogGraphStatsDirectionsComponent implements AfterViewInit {

  /** Graphique */
  @ViewChild('myChart') graph: ElementRef;

  @ViewChild(MatSlideToggle) toggle: MatSlideToggle;

  /** Contexte du graph */
  contexte: any;

  /** graph pour modifier les donnees */
  graphique: any;

  donnees: DataGraphique[] = [];

  /** colonnes du tableau */
  colonnes: string[] = ['abonnement', 'direction', 'favoris'];

  /** nombre abo ou fav*/
  nombre1: number;
  nombre2: number;

  /** titre abo ou fav */
  titre1 = 'Favoris';
  titre2 = 'Favoris';

  /** boolean si histo ou non */
  isHistogramme: boolean;

  /** constante pour le texte nombre abo */
  private abo = 'Nombre abonnés';

  /** légende du graph */
  legendes: { direction: string, couleur1: string; couleur2: string, nbr1: number, nbr2: number }[] = [];

  constructor(private graphUtils: GraphUtilsService, private parametreUtilisateur: ParametreUtilisateurService) { }

  /** Création du graph après le view init */
  ngAfterViewInit() {
    this.parametreUtilisateur.recupererNombreFavoris().subscribe(nbrFav => {
      const data: DataGraphique = new DataGraphique(nbrFav, 'Nombre favoris');
      this.donnees.push(data);
      this.genererLegende(data);
      this.genererGraph();
    });
    this.parametreUtilisateur.recupererNombreAbonnement().subscribe(nbrAbo => {
      const data: DataGraphique = new DataGraphique(nbrAbo, this.abo);
      this.donnees.push(data);
      this.genererLegende(data);
      this.genererGraph();
    });
  }

  // génération ou ajout du graph
  private genererGraph(): void {
    if (!this.graphique) {
      this.graphique = this.graphUtils.creerGraphDoughnut(this.graphUtils.creerDataGraph(this.donnees), undefined, this.graph, false);
    } else {
      this.graphUtils.ajouterData(this.donnees, this.graphique);
    }
  }

  // Génération de la légende
  private genererLegende(data: DataGraphique): void {
    const isAbo = data.nomData === this.abo;
    // si pas encore de légende (donc première data de récup)
    if (this.legendes.length === 0) {
      // Génère la légende avec les couleurs et les valeurs
      Array.from(data.mapData.keys()).forEach((value, index) => {
        this.legendes.push({
          direction: value,
          couleur1: this.graphUtils.colors[index],
          couleur2: this.graphUtils.colorsFonce[index],
          nbr1: undefined,
          nbr2: data.mapData.get(value)
        });
      });
      if (isAbo) {
        this.titre2 = 'Abonnements';
      } else {
        this.titre1 = 'Abonnements';
      }
      // sinon on ajoute les nouvelles valeurs
    } else {
      this.legendes.forEach(objet => objet.nbr1 = data.mapData.get(objet.direction));
    }
    // on affiche le nombre total des premières valeurs
    if (this.legendes.find(l => l.nbr1 !== undefined)) {
      this.nombre1 = this.legendes.map(l => l.nbr1).reduce((n1, n2) => n1 + n2);
    }
    // on ajoute le nombre total des secondes valeurs
    if (this.legendes.find(l => l.nbr2 !== undefined)) {
      this.nombre2 = this.legendes.map(l => l.nbr2).reduce((n1, n2) => n1 + n2);
    }
  }

  // Passage du graph circu à l'histo
  switchGraph(): void {
    this.graphUtils.convertirGrahique(this.donnees, this.graphique);
  }

}
