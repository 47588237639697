import { Component, ViewChild, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { DonneesEnCours } from '../../services/donnees-en-cours';
import { MatCalendar } from '@angular/material/datepicker';
import { MeteoService } from '../../services/meteo.service';
import { IndisponibiliteDuJour, STATUT_SERVICE } from '../../model/model';


@Component({
  selector: 'app-calendrier',
  templateUrl: './calendrier.component.html',
  styleUrls: ['./calendrier.component.scss', '../../commun/style/style-globale-cartes.scss', '../../commun/style/style-calendrier.scss']
})
export class CalendrierComponent implements AfterViewInit {

  @ViewChild(MatCalendar) matCalendar: MatCalendar<Date>;

  private indisponibilites: IndisponibiliteDuJour[];
  private ancienneDate: Date;

  rouge = '#f44336';
  orange = '#ffc107';
  vert = '#8bc34a';
  private fonce = 'ff';
  private normal = 'c0';
  private clair = '80';

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private donneesEnCours: DonneesEnCours,
    private meteoService: MeteoService) { }

  /** Dans le afterview car la vue des jours n'est pas encore initialisée dans le oninit */
  ngAfterViewInit() {
    this.ancienneDate = this.matCalendar.monthView.activeDate;

    this.coloriserCalendrier(this.ancienneDate);

    // Souscription et gestion de tous les événements possibles
    this.matCalendar.monthView.selectedChange.subscribe(d => this.donneesEnCours.changerDate(d));
    this.donneesEnCours.nouvelleIndispo.subscribe((indispo) => {
      if (indispo.refFavoris) {
        this.coloriserCalendrier(this.ancienneDate);
      }
    });
    // Evenement lors du changement de mois
    this.matCalendar.stateChanges.asObservable().subscribe(() => {
      this.coloriserCalendrier(this.matCalendar.activeDate);
      // Changer la date lors du changement du mois
      this.donneesEnCours.changerDate(this.matCalendar.activeDate);
    });
    // Lors du changement de date, modif couleur
    this.donneesEnCours.dateCalendrierSelectionnee.subscribe((d) => {
      this.retirerLeGrasDate(this.ancienneDate);
      this.mettreEnGrasDate(d);
    });
    // Modif lors de l'abonnment/désabonnement
    this.donneesEnCours.refServiceChangementFavoris.subscribe(refService => this.coloriserCalendrier(this.ancienneDate));
  }

  /**
   * Permet de coloriser le calendrier en fonction des indospo
   * Obligation de passer par l'element ref et non l'objet MatCalandar pour coloriser
   * Le chargement des dates s'effectuant après la génération de la vue,
   * la modification du style ne met pas a jour la vue
   */
  private coloriserCalendrier(date: Date): void {
    // Récupération des indispos du service du mois
    this.meteoService.recupererStatutIndispoParJouParFavoris(date)
      .subscribe((indispos: IndisponibiliteDuJour[]) => {
        this.indisponibilites = indispos;

        // Recupération de l'html de tous les jours
        const jours: HTMLCollection = this.elementRef.nativeElement.getElementsByClassName('mat-calendar-body-cell-content');
        // On vérifie que les éléments html sont bien les jours du mois et pas les mois de l'années ou des années
        if (jours.length >= 28 && jours.length <= 31) {
          // Boucle sur tous les jours du mois
          for (const jour in jours) {
            // On vérifie que c'est bien un objet

            if (typeof jours[jour] === 'object') {
              const couleur = this.recupererCouleurSatut(this.indisponibilites[jour]);

              // Actions au mouseover et mouseout
              let onmouseover = 'this.style.background="' + couleur + this.clair + '";';
              let onmouseout = 'this.style.background="' + couleur + this.normal + '";';

              // Suppression des anciens tooltips
              if (jours[jour].children && jours[jour].children.length > 0) {
                jours[jour].removeChild(jours[jour].children[0]);
              }

              if (this.indisponibilites[jour].commentaire) {
                jours[jour].innerHTML = jours[jour].innerHTML +
                  '<div class="tooltipCalendrier" onclick="this.className = \'tooltipCalendrier\';event.stopPropagation();">' +
                  this.indisponibilites[jour].commentaire + '</div>';
                onmouseover += 'this.children[0].className = "tooltipCalendrier afficheCalendrier";';
                onmouseout += 'this.children[0].className = this.children[0].className="tooltipCalendrier";';
              }

              // Apllication via le renderer du fond
              this.renderer.setStyle(jours[jour], 'background-color', couleur + this.normal);
              this.renderer.setAttribute(jours[jour], 'onmouseover', onmouseover);
              this.renderer.setAttribute(jours[jour], 'onmouseout', onmouseout);
              this.renderer.setStyle(jours[jour], 'cursor', 'pointer');
            }
          }
        }
        this.retirerLeGrasDate(this.ancienneDate);
        this.mettreEnGrasDate(date);
        this.modifierCurseur();
      });
  }

  /**
   * Ajoute la mise en gras d'un jour
   * @param jour jour à mettre en gras
   */
  private mettreEnGrasDate(date: Date): void {
    this.ancienneDate = this.modifierGras(date, '800', this.fonce);
  }

  /**
   * Retire la mise en gras d'un jour
   * @param jour jour à mettre en gras
   */
  private retirerLeGrasDate(date: Date): void {
    this.modifierGras(date, 'initial', this.normal);
  }

  /**
   * Modifie la mise en forme du gras
   * @param date
   * @param fontWeight
   * @param nuanceCouleur
   */
  private modifierGras(date: Date, fontWeight: string, nuanceCouleur: string): Date {
    // Mise en grand de la date du jour si l'affichage est sur le mois en cours
    const aujourdhui = date;
    const dateSelectionneCalendrier = this.matCalendar.activeDate;
    if (aujourdhui.getMonth() === dateSelectionneCalendrier.getMonth()) {
      // Recupération de l'html de tous les jours
      const jours = this.elementRef.nativeElement.getElementsByClassName('mat-calendar-body-cell-content');
      // Colorisation de la cellule selectionnée
      if (jours && jours.length >= 28 && jours.length <= 31) {
        this.renderer.setStyle(jours[aujourdhui.getDate() - 1], 'font-weight', fontWeight);
        const couleur = this.recupererCouleurSatut(this.indisponibilites[aujourdhui.getDate() - 1]);
        this.renderer.setStyle(jours[aujourdhui.getDate() - 1], 'background-color', couleur + nuanceCouleur);
        this.renderer.setAttribute(jours[aujourdhui.getDate() - 1], 'onmouseout', 'this.style.background="' + couleur + nuanceCouleur +
          '";if(this.children && this.children[0]) {this.children[0].className = this.children[0].className="tooltipCalendrier"}');
      }
    }
    return aujourdhui;
  }

  /**
   *  Changement de la couleur en fonction du statut
   *
   *  @param indispo indispo du jour à coloriser
   */
  private recupererCouleurSatut(indispo: IndisponibiliteDuJour): string {
    if (indispo.statut === null || indispo.statut === STATUT_SERVICE[2].value) {
      return this.vert;
    } else if (indispo.statut === STATUT_SERVICE[1].value) {
      return this.orange;
    } else {
      return this.rouge;
    }
  }

  /**
   * Désactive le style du curseur au survole sur le calendrier pour les cellules carées
   * */
  private modifierCurseur() {
    const jours: HTMLCollection = this.elementRef.nativeElement.getElementsByClassName('mat-calendar-body-cell');
    // Parcours du calendrier
    for (const jour in jours) {
      // On vérifie que c'est bien un objet
      if (typeof jours[jour] === 'object') {
        this.renderer.setStyle(jours[jour], 'cursor', 'default');
      }
    }
  }
}
