import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-popup-blocked-dialog',
    templateUrl: './popup-blocked-dialog.component.html',
    styleUrls: ['./popup-blocked-dialog.component.scss'],
})
export class PopupBlockedDialogComponent implements OnInit{
    constructor(
        public dialogRef: MatDialogRef<PopupBlockedDialogComponent>,
      ) {}

    public navigatorInstruction: string;
    

    ngOnInit(): void {
      var userAgent = window.navigator.userAgent;
      if (userAgent.indexOf('Chrome') != -1) {
        this.navigatorInstruction = `Pour autoriser les pop-ups sur <strong>Google Chrome</strong> :<br />1. Cliquez sur les trois points en haut à droite.<br />2. Sélectionnez '<strong>Paramètres</strong>'.<br />3. Sous '<strong>Confidentialité et sécurité</strong>', cliquez sur '<strong>Paramètres du site</strong>'.<br />4. Cliquez sur '<strong>Pop-ups et redirections</strong>'.<br />5. Activez '<strong>Autoriser tous les sites à afficher des pop-ups</strong>' ou ajoutez une exception pour ce site.`
      } else if (userAgent.indexOf('Safari') != -1) {
        this.navigatorInstruction = `Pour autoriser les pop-ups sur <strong>Apple Safari</strong> :<br />1. Cliquez sur 'Safari' dans la barre de menu.<br />2. Sélectionnez 'Préférences'.<br />3. Accédez à l'onglet 'Sites web'.<br />4. Dans la colonne de gauche, sélectionnez 'Pop-up Windows'.<br />5. Choisissez 'Allow' (Autoriser) ou ajoutez une exception pour ce site.`
      } else if (userAgent.indexOf('Firefox') != -1) {
        this.navigatorInstruction = `Pour autoriser les pop-ups sur <strong>Mozilla Firefox</strong> :<br />1. Cliquez sur les trois lignes horizontales en haut à droite.<br />2. Sélectionnez '<strong>Paramètres</strong>'.<br />3. Dans le panneau de gauche, choisissez '<strong>Vie privée et sécurité</strong>'.<br />4. Sous '<strong>Permissions</strong>', dé-cochez '<strong>Bloquer les fenêtres popup</strong>' pour désactiver le blocage de pop-ups, ou ajoutez une exception pour ce site.`
      } else {
        this.navigatorInstruction = 'Navigateur non connu, veuillez vous référer à la documentation de votre navigateur pour activé les pop-ups.'
      }
    }
      
    public fermerDialog(): void {
       location.reload();
    }
}