// Composant Angular
import { CdkTableModule } from "@angular/cdk/table";
import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatNativeDateModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { MatRippleModule } from "@angular/material/core";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
// Composant IONIC

import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar } from "@capacitor/status-bar";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
// import { OAuthModule } from 'angular-oauth2-oidc';
// Composant de base de l'application
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
// Composants applicatifs
import { CartographieComponent } from "./commun/cartographie/cartographie.component";
import { ContributionComponent } from "./ecran-mon-bulletin-meteo/contribution/contribution.component";
import { DialogMailToComponent } from "./commun/dialog-mail-to/dialog-mail-to.component";
import { LiensComponent } from "./commun/liens/liens.component";
import { DialogCartographieComponent } from "./commun/dialog-cartographie/dialog-cartographie.component";
import { DialogDiffusionComponent } from "./ecran-mon-bulletin-meteo/dialog-diffusion/dialog-diffusion.component";
import { DialogListeIndisponibiliteComponent } from "./ecran-mon-bulletin-meteo/dialog-liste-indisponibilite/dialog-liste-indisponibilite.component";
import { PopupBlockedDialogComponent } from "./popup-blocked/popup-blocked-dialog/popup-blocked-dialog.component";
import { PopupBlockedComponent } from "./popup-blocked/popup-blocked.component";
import { MesServicesFavorisComponent } from "./ecran-mon-bulletin-meteo/mes-services-favoris/mes-services-favoris.component";
import { MonBulletinMeteoComponent } from "./ecran-mon-bulletin-meteo/mon-bulletin-meteo.component";
import { ServicesParDirectionComponent } from "./ecran-mon-bulletin-meteo/services-par-direction/services-par-direction.component";
import { SupervisionJournaliereDsiComponent } from "./ecran-mon-bulletin-meteo/supervision-journaliere-dsi/supervision-journaliere-dsi.component";
import { AvisComponent } from "./ecran-mon-service/avis/avis.component";
import { FicheServiceComponent } from "./ecran-mon-service/fiche-service/fiche-service.component";
import { MonServiceComponent } from "./ecran-mon-service/mon-service.component";
import { NotationComponent } from "./ecran-mon-service/notation/notation.component";
import { PerformancesComponent } from "./ecran-mon-service/performances/performances.component";
import { PrevisionsComponent } from "./ecran-mon-service/previsions/previsions.component";
import { LuciComponent } from "./ecran-mon-service/luci/luci.component";
import { DialogVideComponent } from "./commun/dialog-vide/dialog-vide.component";
import { EnteteComponent } from "./entete/entete.component";
import { JsonInterceptorService } from "./json-interceptor";
// Authentification Google
import { AuthGuard } from "./services/authentification/auth-guard.service";
import { AppDateAdapter, APP_DATE_FORMATS } from "./services/date.adapter";
import { CalendrierComponent } from "./ecran-mon-bulletin-meteo/calendrier/calendrier.component";
import { RestInterceptor } from "./services/utilitaires/rest-interceptor";
import { SnackBarMessageComponent } from "./commun/snack-bar-message/snack-bar-message.component";
import { CompteurParStatutComponent } from "./ecran-mon-bulletin-meteo/compteur-par-statut/compteur-par-statut.component";
import { DialogGraphStatsDirectionsComponent } from "./ecran-mon-bulletin-meteo/dialog-graph-stats-directions/dialog-graph-stats-directions.component";
import { BoutonGraphStatsComponent } from "./ecran-mon-bulletin-meteo/bouton-graph-stats/bouton-graph-stats.component";
import { DialogAlertComponent } from "./commun/dialog-alert/dialog-alert.component";
import { DialogVideoComponent } from "./commun/dialog-video/dialog-video.component";
import { BoutonTutoComponent } from "./ecran-mon-bulletin-meteo/bouton-tuto/bouton-tuto.component";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { environment } from "../environments/environment";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";

// Gestion de l'internationnalisation
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    // Liste des composants applicatifs
    MonBulletinMeteoComponent,
    MonServiceComponent,
    EnteteComponent,
    PerformancesComponent,
    AvisComponent,
    ContributionComponent,
    MesServicesFavorisComponent,
    ServicesParDirectionComponent,
    SupervisionJournaliereDsiComponent,
    LiensComponent,
    CartographieComponent,
    DialogMailToComponent,
    DialogListeIndisponibiliteComponent,
    DialogCartographieComponent,
    PrevisionsComponent,
    LuciComponent,
    DialogVideComponent,
    NotationComponent,
    DialogDiffusionComponent,
    FicheServiceComponent,
    CalendrierComponent,
    SnackBarMessageComponent,
    DialogDiffusionComponent,
    FicheServiceComponent,
    CalendrierComponent,
    SnackBarMessageComponent,
    CompteurParStatutComponent,
    DialogGraphStatsDirectionsComponent,
    BoutonGraphStatsComponent,
    DialogAlertComponent,
    DialogVideoComponent,
    BoutonTutoComponent,
    EnteteComponent,
    AppComponent,
    PopupBlockedComponent,
    PopupBlockedDialogComponent,
  ],
  imports: [
    // Composants de base
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Routes de l'application
    AppRoutingModule,

    // // Sécurité Google
    // OAuthModule.forRoot(),

    // Firebase oauth2
    AngularFireModule.initializeApp(
      environment.firebaseConfig,
      "MeteoDesServices-dev"
    ),
    AngularFireAuthModule,

    // Composants Ionic
    IonicModule.forRoot(),

    // Material Design
    MatButtonModule,
    MatToolbarModule,
    MatGridListModule,
    MatCardModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatListModule,
    MatSelectModule,
    MatTableModule,
    CdkTableModule,
    MatSnackBarModule,
    MatRippleModule,
    MatSlideToggleModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // Garde de sécurité
    AuthGuard,
    AngularFireAuth,
    PopupBlockedComponent,

    // Configuration IONIC (mise en commentaire pour forcer le raffraichissement du
    // contenu de chaque écran quand on va et vient entre deux écrans)
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // Configuration i18n
    { provide: LOCALE_ID, useValue: "fr" },
    // Ajout d'un intercepteur HTTP
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RestInterceptor, multi: true },
    // Temps d'affichage snack bar
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 6000 } },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    provideAnimationsAsync(),
  ],

  // Composant de démarrage
  bootstrap: [AppComponent],
})
export class AppModule {}
