import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestUtilsService } from './utilitaires/rest-utils.service';
import { Direction, ServiceDto, ServiceAvecStatutDto, NombreNotificationParMois, Service, ServiceGraphiqueDto } from '../model/model';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferentielService {

  constructor(private http: HttpClient, private restUtilService: RestUtilsService) { }

  url = environment.url + '/referentiel';

  /** Chargement de la direction de l'utilisateur */
  chargerDirection(refDirection: string): Observable<Direction> {
    const urlChargerOrga = this.url + '/direction/' + refDirection;
    return this.http.get<Direction>(urlChargerOrga, this.restUtilService.creerHeader());
  }

  /** Chargement de toutes les directions présentes */
  chargerToutesLesDirection(): Observable<Direction[]> {
    const urlChargerOrga = this.url + '/direction';
    return this.http.get<Direction[]>(urlChargerOrga, this.restUtilService.creerHeader());
  }

  /** Chargement de tous les services d'une direction */
  chargerServicesParDirection(refDirection: string, date: Date): Observable<ServiceAvecStatutDto[]> {
    const dateS = this.restUtilService.formaterDatePourUrl(date);

    const urlRecupServices = this.url + '/direction/' + refDirection + '/service' + '?date=' + dateS;
    return this.http.get<ServiceAvecStatutDto[]>(urlRecupServices, this.restUtilService.creerHeaderPostWithAccessToken())
      .pipe(map((s) => this.restUtilService.transformerLesDatesDansLesObjetsEnVraiDate(s)));
  }

  /** Chargement d'une liste de service dto correspondant à la chaine de caractère passée en paramètre */
  recuperServicesParNomEtTrigramme(filtreNom: string): Observable<ServiceDto[]> {

    const urlRecupServicesParNom = this.url + '/service' + '?filtreNom=' + filtreNom;
    return this.http.get<ServiceDto[]>(urlRecupServicesParNom, this.restUtilService.creerHeader());
  }

  /** Chargement de l'image de la cartographie correspondant à la direction */
  recupererCartoDirection(refDirection: string): Observable<Blob> {
    const urlRecupCartoDirection = this.url + '/direction/' + refDirection + '/cartographie';
    return this.http.get<Blob>(urlRecupCartoDirection, this.restUtilService.creerHeaderImage());
  }

  /** Chargement des informations du service */
  chargerMonService(refService: string, date: Date): Observable<ServiceAvecStatutDto> {
    const urlChargerMonService = this.url + '/service/' + refService + '?date=' + this.restUtilService.formaterDatePourUrl(date);
    return this.http.get<ServiceAvecStatutDto>(urlChargerMonService, this.restUtilService.creerHeaderWithAccessToken());
  }

  /** Chargement des stats concerant le nombre de notification par mois sur les 12 derniers mois pour un service */
  chargerStats(refService: string): Observable<NombreNotificationParMois[]> {
    return this.http.get<NombreNotificationParMois[]>(this.url + '/service/' + refService + '/stats', this.restUtilService.creerHeader())
      .pipe(map((r) => this.restUtilService.transformerLesDatesDansLesObjetsEnVraiDate(r)));

  }

  /** Chargement de la documentation du service */
  recupererServiceAvecDescription(refService: string): Observable<Service> {
    const urlService = this.url + '/service/' + refService + '/documentation';
    return this.http.get<Service>(urlService, this.restUtilService.creerHeader());
  }

  /** Chargement des services de l'utilisateur favoris avec leurs statut pour la date sélectionné */
  recupererServicesFavorisParDate(date: Date): Observable<ServiceAvecStatutDto[]> {
    const urlServicesFavoris = this.url + '/services/favoris?date=' + this.restUtilService.formaterDatePourUrl(date);
    return this.http.get<ServiceAvecStatutDto[]>(urlServicesFavoris, this.restUtilService.creerHeaderWithAccessToken());
  }

  /** Chargement des urls des graohique si présent dans l'export cartage */
  recupererFichierGraphique(refService: string) {
    const urlRecupGraph = this.url + '/service/' + refService + '/graphiques';
    return this.http.get<ServiceGraphiqueDto>(urlRecupGraph, this.restUtilService.creerHeader());
  }

  /** Chargement de la video de tuto */
  recupererVideoTuto(): Observable<Blob> {
    const urlRecupCartoDirection = this.url + '/drive/video/tuto';
    return this.http.get<Blob>(urlRecupCartoDirection, this.restUtilService.creerHeaderImage());
  }

  /** Chargement des donnees du Gsheet de Supervision */
  chargerDonneesSupervision(): Observable<any> {

    const urlchargerDonneesSupervision = environment.url + '/supervision';
    return this.http.get<any>(urlchargerDonneesSupervision, this.restUtilService.creerHeaderPostWithAccessToken());
  }
}
