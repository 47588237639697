import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Indisponibilite, RECUPERER_IMAGE_METEO } from '../../model/model';
import { ParametreUtilisateurService } from '../../services/parametre-utilisateur.service';

@Component({
  selector: 'app-dialog-diffusion',
  templateUrl: './dialog-diffusion.component.html',
  styleUrls: ['./dialog-diffusion.component.scss']
})

export class DialogDiffusionComponent implements OnInit {

  /** fonction de transformation d'un statut en image. */
  recupererImageMeteo = RECUPERER_IMAGE_METEO;

  nombreAbo: number | undefined = undefined;

  boutonDesactive = true;

  /** Booleen permettant de savoir si la recherche du nombre d'abonnés est terminé ou non */
  chargementNombreAbonnesTermine = false;

  /** Indisponibilité à diffuser */
  indispo: Indisponibilite;

  /** Booleen permettant de savoir si l'appel à la diffusion vient de la dialog historique */
  appelDepuisHistorique: boolean;

  constructor(private dialogRef: MatDialogRef<DialogDiffusionComponent>, private parametreUtilisateurService: ParametreUtilisateurService,
    @Inject(MAT_DIALOG_DATA) public dataInput: { indispo: Indisponibilite, appelDepuisHistorique: boolean }) {
  }

  ngOnInit() {
    this.indispo = this.dataInput.indispo;
    this.appelDepuisHistorique = this.dataInput.appelDepuisHistorique;
    this.parametreUtilisateurService.recupererNombreAbonneService(this.indispo.service.reference).subscribe((n) => {
      // flag en plus du nombre d'abo car 0 est un falsy
      this.nombreAbo = n;
      this.chargementNombreAbonnesTermine = true;
    });
  }

  annuler() {
    this.dialogRef.close(null);
  }

  /** Enregistrer et envoyer s'il y a des abonnés au service */
  enregistrerEtEnvoyer() {
    this.dialogRef.close(this.nombreAbo > 0);
  }
}
