<mat-card class="conteneur">
  <!-- Titre de la carte -->
  <mat-card-subtitle class="ion-text-center">
    <mat-icon>date_range</mat-icon>MES PREVISIONS
  </mat-card-subtitle>
  <!-- Calendrier -->
  <mat-card-content class="calendrier">
    <mat-calendar #calendar> </mat-calendar>
  </mat-card-content>
  <!-- Légende du calendrier -->
  <mat-card-footer class="centrer">
    <div>
      <div class="disque" [style.background-color]="vert"></div>
      <span>En service</span>
    </div>
    <div>
      <div class="disque" [style.background-color]="orange"></div>
      <span>Service dégradé</span>
    </div>
    <div>
      <div class="disque" [style.background-color]="rouge"></div>
      <span>Service à l'arrêt</span>
    </div>
  </mat-card-footer>
</mat-card>