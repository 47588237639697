import { NativeDateAdapter } from '@angular/material/core';

export const MOIS = ['janv', 'févr', 'mars', 'avril', 'mai', 'juin', 'juill', 'août', 'sept', 'oct', 'nov', 'déc'];

export class AppDateAdapter extends NativeDateAdapter {

    /**
     * Pour commencer le jour à lundi dans les calendriers
     */
    getFirstDayOfWeek(): number {
        return 1;
    }

    /**
     * Convertie une chaine de charactere dd/mm/yyyy en date
     */
    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            const str = value.split('/');
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    /**
     *
     * Convertie une date au format dd/mm/yyyy ou mm/yyyy
     *
     * @param date
     *
     * @param displayFormat format voulu
     */
    format(date: Date, displayFormat: string): string {
        if (displayFormat === 'input') {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
        } else if (displayFormat === 'inputMonth') {
            const month = MOIS[date.getMonth()].toLocaleUpperCase();
            const year = date.getFullYear();
            return month + ' ' + year;
        } else {
            return date.toDateString();
        }
    }

    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
}

export const APP_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: 'inputMonth',
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
