import { Component, ViewChild, AfterViewInit, Renderer2, ElementRef, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { MatCalendar, MatCalendarHeader } from '@angular/material/datepicker';
import { MeteoService } from '../../services/meteo.service';
import { IndisponibiliteDuJour, STATUT_SERVICE } from '../../model/model';

@Component({
  selector: 'app-previsions',
  templateUrl: './previsions.component.html',
  styleUrls: ['./previsions.component.scss', '../../commun/style/style-globale-cartes.scss', '../../commun/style/style-calendrier.scss'],
})
export class PrevisionsComponent implements AfterViewInit, OnChanges {

  // Récupération du calendrier
  @ViewChild(MatCalendar) matCalendar: MatCalendar<Date>;
  @ViewChild(MatCalendarHeader) matCalendarHeader: MatCalendarHeader<Date>;

  // Référence du service
  @Input() refService: string;
  /** Nom du service en cours de visualisation */
  @Input() serviceNom: string;

  rouge = '#f44336';
  orange = '#ffc107';
  vert = '#8bc34a';
  private fonce = 'ff';
  private normal = 'c0';
  private clair = '80';

  dernierJourEnGras: number;
  indisponibilites: IndisponibiliteDuJour[];

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private meteoService: MeteoService) {
  }

  ngAfterViewInit() {
    // Coloruse le calendrier
    this.coloriserCalendrier(this.matCalendar.monthView.activeDate);
    // Met en gras le jour actuel
    this.mettreEnGrasDateDuJour();
    // Gestion du style du curseur
    this.modificationCurseurStyle();

    // Evenement lors du changement de mois
    this.matCalendar.stateChanges.asObservable().subscribe(() => {
      this.coloriserCalendrier(this.matCalendar.activeDate);
      this.modificationCurseurStyle();
    });
  }

  /**
   * S'execute quand la valeur de l'input change
   * Permet de raffraichir les données du composant lorsque le service est changé via la barre de recherche
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const change in changes) {
      if (change === 'refService' && changes[change] instanceof SimpleChange
        && changes[change].currentValue && !changes[change].isFirstChange()) {
        this.ngAfterViewInit();
      }
    }
  }


  /**
   * Permet de coloriser le calendrier en fonction des indospo
   * Obligation de passer par l'element ref et non l'objet MatCalandar pour coloriser
   * Le chargement des dates s'effectuant après la génération de la vue,
   * la modification du style ne met pas a jour la vue
   */
  private coloriserCalendrier(date: Date): void {
    // Récupération des indispos du service du mois
    this.meteoService.recupererStatutIndispoParJour(date, this.refService).subscribe((indispos: IndisponibiliteDuJour[]) => {

      this.indisponibilites = indispos;
      // Recupération de l'html de tous les jours
      const jours: HTMLCollection = this.elementRef.nativeElement.getElementsByClassName('mat-calendar-body-cell-content');

      // On vérifie que pas énum de mois ou d'années
      if (jours.length >= 28 && jours.length <= 31) {
        // Boucle sur tous les jours du mois
        for (const jour in jours) {
          // On vérifie que c'est bien un objet
          if (typeof jours[jour] === 'object') {
            const couleur = this.recupererCouleurStatut(this.indisponibilites[jour]);

            // Apllication via le renderer du fond
            this.renderer.setStyle(jours[jour], 'background-color', couleur + this.normal);

            // Actions au mouseover et mouseout
            let onmouseover = 'this.style.background="' + couleur + this.clair + '";';
            let onmouseout = 'this.style.background="' + couleur + this.normal + '";';

            // Suppression des anciens tooltips
            if (jours[jour].children && jours[jour].children.length > 0) {
              jours[jour].removeChild(jours[jour].children[0]);
            }

            // Ajout du tooltip
            if (this.indisponibilites[jour].commentaire) {
              jours[jour].innerHTML = jours[jour].innerHTML +
                '<div class="tooltipCalendrier" onclick="this.className = \'tooltipCalendrier\';event.stopPropagation();">' +
                this.indisponibilites[jour].commentaire + '</div>';
              onmouseover += 'this.children[0].className = "tooltipCalendrier afficheCalendrier";';
              onmouseout += 'this.children[0].className = this.children[0].className="tooltipCalendrier";';
            }

            this.renderer.setAttribute(jours[jour], 'onmouseover', onmouseover);
            this.renderer.setAttribute(jours[jour], 'onmouseout', onmouseout);
            this.renderer.setStyle(jours[jour], 'cursor', 'pointer');
          }
        }
      }
      this.mettreEnGrasDateDuJour();
      this.modificationCurseurStyle();
    });
  }

  /**
   * Permet la mise en gras d'un jour
   * @param jour jour à mettre en gras
   */
  private mettreEnGrasDateDuJour(): void {
    // Mise en grand de la date du jour si l'affichage est sur le mois en cours
    const aujourdhui = new Date();
    const dateSelectionneCalendrier = this.matCalendar.activeDate;
    if (aujourdhui.getMonth() === dateSelectionneCalendrier.getMonth()) {
      // Recupération de l'html de tous les jours
      const jours = this.elementRef.nativeElement.getElementsByClassName('mat-calendar-body-cell-content');
      // Colorisation de la cellule selectionnée
      if (jours) {
        this.renderer.setStyle(jours[aujourdhui.getDate() - 1], 'font-weight', '800');
      }
    }
  }

  /**
   *  Changement de la couleur en fonction du statut
   *
   *  @param indispo indispo du jour à coloriser
   */
  private recupererCouleurStatut(indispo: IndisponibiliteDuJour): string {
    if (indispo.statut === null || indispo.statut === STATUT_SERVICE[2].value) {
      return this.vert;
    } else if (indispo.statut === STATUT_SERVICE[1].value) {
      return this.orange;
    } else {
      return this.rouge;
    }
  }

  /** Désactive le style du curseur au survole sur le calendrier  */
  private modificationCurseurStyle() {
    const jours: HTMLCollection = this.elementRef.nativeElement.getElementsByClassName('mat-calendar-body-cell');
    // Parcours du calendrier
    for (const jour in jours) {
      // On vérifie que c'est bien un objet
      if (typeof jours[jour] === 'object') {
        this.renderer.setStyle(jours[jour], 'cursor', 'default');
      }
    }
  }
}
