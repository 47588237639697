import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MonBulletinMeteoComponent } from './ecran-mon-bulletin-meteo/mon-bulletin-meteo.component';
import { MonServiceComponent } from './ecran-mon-service/mon-service.component';
import { AuthGuard } from './services/authentification/auth-guard.service';

const routes: Routes = [
    { path: '', redirectTo: 'mon-bulletin-meteo', pathMatch: 'full' },
    { path: 'mon-service/:refService', component: MonServiceComponent, canActivate: [AuthGuard] },
    { path: 'mon-bulletin-meteo', component: MonBulletinMeteoComponent, canActivate: [AuthGuard] },
    { path: '**', redirectTo: 'mon-bulletin-meteo', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})

export class AppRoutingModule { }
