import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
// import { OAuthService } from 'angular-oauth2-oidc';
import { AuthentificationService } from '../authentification/authentification.service';

/** Classe utilitaire pour les appels REST */
@Injectable({ providedIn: 'root' })
export class RestUtilsService {

  /** Constructeur pour l'injection de composant */
  constructor(
    // private oauthService: OAuthService,
    private authentificationService: AuthentificationService) { }

  /** Ajoute l'idToken dans l'entete Authorization */
  private ajouterHeaderAuthorization(headers): void {
    // const token = this.oauthService.getIdToken();
    const token = this.authentificationService.getIdToken();
    if (token) {
      headers.Authorization = token;
    }
  }

  /** Ajoute l'accessToken dans l'entete AccessToken */
  private ajouterHeaderAccessToken(headers): void {
    // const token = this.oauthService.getAccessToken();
    const token = this.authentificationService.getAccessToken();
    if (token) {
      headers.AccessToken = token;
    }
  }

  /** Creation des entetes d'appel à une méthode REST */
  public creerHeader(): { headers: HttpHeaders } | undefined {
    const headers: any = { 'Content-Type': 'application/json' };
    this.ajouterHeaderAuthorization(headers);
    return { headers: new HttpHeaders(headers), };
  }

  /** Creation des entetes d'appel à une méthode REST */
  public creerHeaderWithAccessToken(): { headers: HttpHeaders } | undefined {
    const headers: any = { 'Content-Type': 'application/json' };
    this.ajouterHeaderAuthorization(headers);
    this.ajouterHeaderAccessToken(headers);
    return { headers: new HttpHeaders(headers), };
  }

  /** Creation des entetes d'appel à une méthode REST avec un retour de type IMAGE*/
  public creerHeaderImage(): { headers: HttpHeaders, responseType: any } | undefined {
    const headers: any = { 'Content-Type': 'application/json' };
    this.ajouterHeaderAuthorization(headers);
    return { headers: new HttpHeaders(headers), responseType: 'blob' };
  }

  /** Creation des entetes d'appel à une méthode REST de type POST */
  public creerHeaderPost(): { headers: HttpHeaders } | undefined {
    const headers: any = {};
    this.ajouterHeaderAuthorization(headers);
    return { headers: new HttpHeaders(headers) };
  }

  /** Creation des entetes d'appel à une méthode REST de type POST qui nécessitera un appel à une API */
  public creerHeaderPostWithAccessToken(): { headers: HttpHeaders } | undefined {
    const headers: any = {};
    this.ajouterHeaderAuthorization(headers);
    this.ajouterHeaderAccessToken(headers);
    return { headers: new HttpHeaders(headers) };
  }

  /** Formatage des dates à ajouter dans les url (Format: yyyy-MM-dd) */
  public formaterDatePourUrl(date: Date): string {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2);
  }

  /** Formatage des dates heures à ajouter dans les url (Format: yyyy-MM-ddThh:mm:ss) */
  public formaterDateHeurePourUrl(date: Date): string {
    return this.formaterDatePourUrl(date) + 'T' + ('0' + (date.getHours())).slice(-2) + ':' + ('0' + (date.getMinutes())).slice(-2)
      + ':' + ('0' + (date.getSeconds())).slice(-2) + '.000Z';
  }

  /** Parcours l'objet et transforme les string contenant des dates en Date */
  public transformerLesDatesDansLesObjetsEnVraiDate(obj: any) {

    // Parcours des attributs
    for (const attr in obj) {

      // Si c'est une string
      if (obj[attr] && typeof obj[attr] === 'string') {
        const s: string = (obj[attr] as string);
        if (s.charAt(4) === '-' && s.charAt(7) === '-') {
          obj[attr] = new Date(s);
        }
      } else if (obj[attr] && obj[attr] instanceof Object) {
        // Si c'est un objet
        this.transformerLesDatesDansLesObjetsEnVraiDate(obj[attr]);
      }
    }
    return obj;
  }

  /** transforme un json en une map*/
  public formaterJsonToMap(json: any): Map<any, any> {
    const tabMap: any = Object.keys(json).map((key) => [key, json[key]]);
    return new Map(tabMap);
  }

}
