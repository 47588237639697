import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestUtilsService } from './utilitaires/rest-utils.service';
import { Observable } from 'rxjs';
import { AvisDto } from '../model/model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ParametreUtilisateurService {

  constructor(private http: HttpClient, private restUtilService: RestUtilsService) { }

  /** Chargement de la liste des avis à ajouter à l'écran mon service */
  chargerAvis(refService: string): Observable<AvisDto[]> {
    return this.http.get<AvisDto[]>(environment.url + '/avis/' + refService, this.restUtilService.creerHeader());
  }

  /** Sauvegarde d'un avis */
  sauvegarderAvis(avisDto: AvisDto): Observable<AvisDto> {
    return this.http.post<AvisDto>(environment.url + '/avis', avisDto, this.restUtilService.creerHeader());
  }

  /** Recupération du nombre d'abonne au service */
  recupererNombreAbonneService(refService: string): Observable<number> {
    const urlNombreAbonne = environment.url + '/service/' + refService + '/abonne';
    return this.http.get<number>(urlNombreAbonne, this.restUtilService.creerHeader());
  }

  /** Recupération du nombre de favoris au service */
  recupererNombreFavorisService(refService: string): Observable<number> {
    const urlNombreFavoris = environment.url + '/service/' + refService + '/favoris';
    return this.http.get<number>(urlNombreFavoris, this.restUtilService.creerHeader());
  }

  /** Ajouter/retirer un service aux abonnements */
  ajouterRetirerLeServiceEnAbonnement(refService: string, etat: boolean): Observable<boolean> {
    const urlFavorisService = environment.url + '/service/' + refService + '/abonnement/' + etat;
    return this.http.put<boolean>(urlFavorisService, null, this.restUtilService.creerHeaderPostWithAccessToken());
  }

  /** Ajouter/retirer un service aux favoris */
  ajouterRetirerLeServiceEnFavoris(refService: string, etat: boolean): Observable<boolean> {
    const urlFavorisService = environment.url + '/service/' + refService + '/favoris/' + etat;
    return this.http.put<boolean>(urlFavorisService, null, this.restUtilService.creerHeaderPostWithAccessToken());
  }

  /** Recupère le nombre total de favoris par service */
  recupererNombreFavoris(): Observable<Map<string, number>> {
    const urlFavoris = environment.url + '/favoris';
    return this.http.get<Map<string, number>>(urlFavoris, this.restUtilService.creerHeader())
      .pipe(map((json) => this.restUtilService.formaterJsonToMap(json)));
  }

  /** Recupère le nombre total de abonnement par service */
  recupererNombreAbonnement(): Observable<Map<string, number>> {
    const urlAbonnement = environment.url + '/abonnements';
    return this.http.get<Map<string, number>>(urlAbonnement, this.restUtilService.creerHeader())
      .pipe(map((json) => this.restUtilService.formaterJsonToMap(json)));
  }
}
