<mat-card>
    <mat-card-subtitle class="ion-text-center">
        <mat-icon>report</mat-icon>MES INDICATEURS
    </mat-card-subtitle>
    <mat-card-content>
        <ion-row class="ion-align-items-center row-performances">

            <!------------->
            <!-- Graph 1 -->
            <!------------->
            <ion-col class="ion-text-center" size-xs="12" size-md="6" size-xl="4">

                <!-- Graph1 si url présente -->
                <div [style.display]="serviceGraphiquesDto && serviceGraphiquesDto.urlGraph1 ? 'block' : 'none'" class="div-iframe">
                    <iframe #iFrameGraph1 class="iframe-graph" frameborder="0" allowfullscreen></iframe>
                </div>

                <!-- Graph1 si fichier est un png -->
                <div [style.display]="serviceGraphiquesDto && !serviceGraphiquesDto.urlGraph1 && serviceGraphiquesDto.fichierGraph1 ? 'block' : 'none'"
                    class="div-img">
                    <img #fichierImgGraph1>
                </div>

                <!-- Graphique annuel -->
                <!-- Display none et non un *ngIf pour traiter le cas du changement de service par l'entete quand le service précédent n'a pas de graphique -->
                <div [style.display]="serviceGraphiquesDto && !serviceGraphiquesDto.urlGraph1 && !serviceGraphiquesDto.fichierGraph1 ? 'block' : 'none'">
                    <canvas #myChartAnnuel></canvas>
                </div>
                <div *ngIf="!serviceGraphiquesDto" class="spinner-graph">
                    <mat-spinner diameter="100"></mat-spinner>
                </div>

            </ion-col>

            <!------------->
            <!-- Graph 2 -->
            <!------------->
            <ion-col  class="ion-text-center composant-nombre-abonne" size-xs="12" size-md="6" size-xl="4">

                <!-- Graph2 si url présente -->
                <div [style.display]="serviceGraphiquesDto && serviceGraphiquesDto.urlGraph2 ? 'block' : 'none'" class="div-iframe">
                    <iframe #iFrameGraph2 class="iframe-graph" frameborder="0" allowfullscreen></iframe>
                </div>

                <!-- Graph2 si fichier est un png -->
                <div [style.display]="serviceGraphiquesDto && !serviceGraphiquesDto.urlGraph2 && serviceGraphiquesDto.fichierGraph2 ? 'block' : 'none'">
                    <img #fichierImgGraph2>
                </div>

                <!-- Nombre d'abonné -->
                <div *ngIf="serviceGraphiquesDto && !serviceGraphiquesDto.urlGraph2 && !serviceGraphiquesDto.fichierGraph2"
                    class="margin-bottom">
                    <ion-row class="ion-align-items-center gris">
                        <ion-col> A ce jour </ion-col>
                    </ion-row>
                    <ion-row class="ion-align-items-center nombre-abonne gris">
                        <ion-col class="nombre-abonne"><strong>{{nbAbonnes ? nbAbonnes : 0}}</strong></ion-col>
                    </ion-row>
                    <ion-row class="ion-align-items-center gris texte-abo">
                        <ion-col class="abonne">
                            <strong>{{nbAbonnes > 1 ? "abonnés" : "abonné"}}</strong>
                        </ion-col>
                    </ion-row>
                </div><!-- Nombre de favoris -->
                <div *ngIf="serviceGraphiquesDto && !serviceGraphiquesDto.urlGraph2 && !serviceGraphiquesDto.fichierGraph2">
                    <ion-row class="ion-align-items-center gris">
                        <ion-col> A ce jour </ion-col>
                    </ion-row>
                    <ion-row class="ion-align-items-center nombre-abonne gris">
                        <ion-col class="nombre-abonne"><strong>{{nbFavoris}}</strong></ion-col>
                    </ion-row>
                    <ion-row class="ion-align-items-center gris texte-abo">
                        <ion-col class="abonne">
                            <strong>favoris</strong>
                        </ion-col>
                    </ion-row>
                </div>
                <div *ngIf="!serviceGraphiquesDto" class="spinner-graph">
                    <mat-spinner diameter="100"></mat-spinner>
                </div>

            </ion-col>

            <!------------->
            <!-- Graph 3 -->
            <!------------->
            <ion-col size-xs="12" size-md="6" size-xl="4">

                <!-- Graph3 si url présente -->
                <div [style.display]="serviceGraphiquesDto && serviceGraphiquesDto.urlGraph3 ? 'block' : 'none'" class="div-iframe">
                    <iframe #iFrameGraph3 class="iframe-graph" frameborder="0" allowfullscreen></iframe>
                </div>

                <!-- Graph3 si fichier est un png -->
                <div [style.display]="serviceGraphiquesDto && !serviceGraphiquesDto.urlGraph3 && serviceGraphiquesDto.fichierGraph3 ? 'block' : 'none'">
                    <img #fichierImgGraph3>
                </div>

                <!-- Graphique (s'il y a des données à afficher) -->
                <!-- Display none et non un *ngIf pour traiter le cas du changement de service par l'entete quand le service précédent n'a pas de graphique -->
                <div [style.display]="serviceGraphiquesDto && !serviceGraphiquesDto.urlGraph3 && !serviceGraphiquesDto.fichierGraph3  && !graphEstVide ? 'block' : 'none'">
                    <canvas #myChartNotif></canvas>
                </div>

                <!-- Message s'il n'y a aucune donnée à afficher dans le graph -->
                <div *ngIf="serviceGraphiquesDto && !serviceGraphiquesDto.urlGraph3 && !serviceGraphiquesDto.fichierGraph3  && graphEstVide"
                    class="ion-text-center message-aucune-notif gris" class="ion-align-self-center">
                    Aucune notification n'a été envoyée sur les 12 derniers mois !
                </div>

                <div *ngIf="!serviceGraphiquesDto" class="spinner-graph">
                    <mat-spinner diameter="100"></mat-spinner>
                </div>

            </ion-col>

        </ion-row>
    </mat-card-content>
</mat-card>