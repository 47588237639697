import { Component, Inject } from '@angular/core';
import { RECUPERER_IMAGE_METEO, Indisponibilite } from '../../model/model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-liste-indisponibilite',
  templateUrl: './dialog-liste-indisponibilite.component.html',
  styleUrls: ['./dialog-liste-indisponibilite.component.scss']
})
export class DialogListeIndisponibiliteComponent {

  /** Liste des colonnes affichées dans le tableau */
  listeDesColonnes: string[] = ['createur', 'dateDebut', 'dateFin', 'statut', 'commentaire', 'actions'];

  /** Fonction de transformation des statuts en image */
  recupererImageMeteo = RECUPERER_IMAGE_METEO;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { liste: Indisponibilite[], nom: string }) { }

}
