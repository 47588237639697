import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';

import { Utilisateur, Documentation, Direction } from '../model/model';
import { DonneesEnCours } from '../services/donnees-en-cours';

@Component({
  selector: 'app-mon-bulletin-meteo',
  templateUrl: './mon-bulletin-meteo.component.html',
  styleUrls: ['./mon-bulletin-meteo.component.scss']
})
export class MonBulletinMeteoComponent implements OnInit {

  utilisateur: Utilisateur;

  /** Flag indiquant si l'utilisateur a le droit de contribuer */
  utilisateurEstContributeur = false;

  /** Flag indiquant si l'utilisateur a le droit de voir la partie Supervision */
  utilisateurEstSuperviseur = false;

  /** Documentation de la direction */
  documentations: Documentation[];

  /** Direction */
  direction: Direction;

  public dateCalendrier: Date = new Date();

  constructor(private donneesEnCours: DonneesEnCours, private renderer: Renderer2, private el: ElementRef) { }

  /** Pour raffraichir la page lorsqu'on revient dessus
   * https://blog.ionicframework.com/navigating-lifecycle-events/
   */
  ionViewDidEnter() {
    this.ngOnInit();
  }

  ngOnInit() {
    // Récupération de l'utilisateur
    this.donneesEnCours.utilisateurEnCours.subscribe((u) => this.utilisateurEstContributeur = u.flagContributeur);
    this.donneesEnCours.utilisateurEnCours.subscribe((u) => this.utilisateurEstSuperviseur = u.flagSuperviseur);

    // Récupération des documentations de la direction
    this.donneesEnCours.directionEnCours.subscribe((dir) => {
      if (dir.documentations) {
        this.direction = dir;
        this.documentations = dir.documentations;
      }
    });

    // Ajout d'une souscription à la sélection d'une date dans le calendrier
    this.donneesEnCours.dateCalendrierSelectionnee.subscribe(dateSelectionnee => this.dateCalendrier = dateSelectionnee);
  }
}
