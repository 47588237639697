<ion-grid class="contenu">
  <ion-row>
    <!-- nom du service -->
    <ion-col size="5" class="alignement-vertical center">
      <img [src]=recupererImageMeteo(indispo.statut) id="image" [matTooltip]="indispo.statut">
      <div class="nom ion-text-center">{{indispo.service.nom}}</div>
    </ion-col>
    <!-- information sur le nombre de personnes abonnées-->
    <ion-col size="7" class="alignement-vertical center">
      <div *ngIf="!!chargementNombreAbonnesTermine">
        <div *ngIf="nombreAbo === 0 || nombreAbo > 0; else sansGoogleGroup">
          <div *ngIf="nombreAbo > 0" class="titre">Vous adressez cette information à</div>
          <a [href]="'https://groups.google.com/a/veolia.com/forum/#!members/fr.water.vedif.meteo-des-services-' + indispo.service.trigramme + '.all.groups'"
            target="_blank" matTooltip="Afficher les destinataires de la notification">
            <div *ngIf="nombreAbo > 0" class="abo">{{ nombreAbo === 1 ? nombreAbo + " abonné" : nombreAbo + "
              abonnés"}}</div>
          </a>
          <div *ngIf="nombreAbo === 0 || !nombreAbo" [style.color]="'#d32f2f'" class="titre">Vous n'adressez cette
            information à</div>
          <div *ngIf="nombreAbo === 0 || !nombreAbo" [style.color]="'#d32f2f'" class="abo">personne</div>
        </div>
      </div>
      <!-- si groupe inexistant, message d'information-->
      <ng-template #sansGoogleGroup>
        <span [style.color]="'#d32f2f'">
          Diffusion impossible - Groupe inexistant.<br />Merci d'ouvrir un ticket LUCI concernant la création de la
          liste de diffusion Google
        </span>
      </ng-template>
      <mat-spinner *ngIf="!chargementNombreAbonnesTermine"></mat-spinner>
    </ion-col>
  </ion-row>
  <!-- description de l'indispo-->
  <ion-row class="ion-align-items-center">
    <ion-col size="12">
      <div class="alignement-vertical">
        <div *ngIf="indispo.dateDebut != indispo.dateFin" class="date">
          Du {{indispo.dateDebut | date:'EEEE dd LLLL yyyy' | titlecase}} au {{indispo.dateFin | date:'EEEE dd LLLL
          yyyy' | titlecase}}.
        </div>
        <div *ngIf="indispo.dateDebut == indispo.dateFin" class="date">
          Le {{indispo.dateDebut | date:'EEEE dd LLLL yyyy' | titlecase}}.
        </div>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMaxRows="12" disabled>{{indispo.commentaire}}</textarea>
      </div>
    </ion-col>
  </ion-row>
  <!-- action possible, envoyer ou annuler-->
  <ion-row mat-dialog-actions class="ion-justify-content-around">
    <div *ngIf="!!chargementNombreAbonnesTermine">
      <button matTooltip="Ne pas envoyer" mat-button color="accent" (click)="annuler()">Annuler</button>
      <!-- Désactivation du bouton Enregistrer/Envoyer s'il n'y aucun abonné au service et la dialogue a été ouverte depuis l'historique (Comme l'indispo est déjà sauvegardé) -->
      <button [matTooltip]="'Envoyer l\'information à tous les abonnés du service ' + indispo.service.nom" mat-button
        color="primary" (click)="enregistrerEtEnvoyer()" cdkFocusInitial [disabled]="(nombreAbo === 0 || !nombreAbo) && appelDepuisHistorique">
        {{(nombreAbo === 0 || !nombreAbo) ? 'Enregistrer' : 'Envoyer'}}
      </button>
    </div>
  </ion-row>
</ion-grid>