import { Component} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupBlockedDialogComponent } from './popup-blocked-dialog/popup-blocked-dialog.component';

@Component({
  selector: 'app-popup-blocked',
  templateUrl: './popup-blocked.component.html',
  styleUrls: ['./popup-blocked.component.scss'],
})
export class PopupBlockedComponent {
  constructor(private dialog: MatDialog) { }

  openDialog() {
    const dialogRef = this.dialog.open(PopupBlockedDialogComponent, {
      disableClose: true,
    });
  }
}