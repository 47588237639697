<mat-card>
  <mat-card-subtitle>
    <mat-icon *ngIf="!isChargementDirection && directions">search</mat-icon>
    <mat-spinner class="spinner-direction" color="accent" *ngIf="isChargementDirection && directions" diameter="20"></mat-spinner>
    <!-- Select-box de la direction -->
    <mat-form-field id="select-box" *ngIf="direction">
      <mat-select [value]="direction.reference" (selectionChange)="selectionnerDirection($event)">
        <mat-option *ngFor="let orga of directions" [value]="orga.reference">
          {{orga.nom | uppercase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-subtitle>
  <mat-card-content>

    <!-- Ligne des compteurs par statut -->
    <app-compteur-par-statut [compteurArret]="compteurArret" [compteurDegrade]="compteurDegrade" [compteurEnService]="compteurEnService"
      *ngIf="direction"></app-compteur-par-statut>

    <!-- Tableau des services de la direction sélectionnée -->
    <ion-row>
      <ion-col>
        <mat-table [dataSource]="services" matSort *ngIf="direction">
          <ng-container cdkColumnDef="prefs">
            <!-- Colonne Préférences -->
            <mat-header-cell *cdkHeaderCellDef>
              <!-- Bouton pour coher/decocher favoris dans le header -->
              <div [class]="tousFavoris?'bouton-coche':'bouton-decoche'" *ngIf="!isChargementFavoris">
                <button mat-icon-button [disabled]="isChargementFavoris" (click)="ajouterTousLesFavoris(!tousFavoris)"
                  [matTooltip]="(tousFavoris ? 'Retirer' : 'Ajouter'  ) + ' tous les services ' + (tousFavoris ?  'de' : 'à') + ' vos favoris'">
                  <mat-icon>{{tousFavoris?'favorite':'favorite_border'}}</mat-icon>
                </button>
              </div>
              <mat-spinner *ngIf="isChargementFavoris" diameter="20"></mat-spinner>
              <!-- Bouton pour coher/decocher abonnement dans le header -->
              <div [class]="tousAbonnes?'bouton-coche':'bouton-decoche'" *ngIf="!isChargementAbonnement">
                <button mat-icon-button [disabled]="isChargementAbonnement" (click)="ajouterTousLesAbonnements(!tousAbonnes)"
                  [matTooltip]="(tousAbonnes ? 'Retirer' : 'Ajouter') + ' tous les services ' + (tousAbonnes ? 'de' : 'à') + ' vos abonnement'">
                  <mat-icon>{{tousAbonnes?'email':'mail_outline'}}</mat-icon>
                </button>
              </div>
              <mat-spinner *ngIf="isChargementAbonnement" diameter="20"></mat-spinner>
            </mat-header-cell>
            <mat-cell *cdkCellDef="let service">
              <!-- Bouton favoris -->
              <div [class]="(service.refFavoris)?'bouton-coche':'bouton-decoche'" *ngIf="!(service.modificationFavorisEnCours || isChargementFavoris)">
                <button mat-icon-button [disabled]="service.modificationFavorisEnCours || isChargementFavoris" (click)="service.modificationFavorisEnCours = true;ajouterFavoris(service.reference, service.nom, !service.refFavoris)">
                  <mat-icon>{{(service.refFavoris)?'favorite':'favorite_border'}}</mat-icon>
                </button>
              </div>
              <mat-spinner *ngIf="service.modificationFavorisEnCours || isChargementFavoris" diameter="20"></mat-spinner>
              <!-- Bouton abonnement -->
              <div [class]="(service.abonnementActif)?'bouton-coche':'bouton-decoche'" *ngIf="!service.modificationAbonnementEnCours">
                <button mat-icon-button [disabled]="service.modificationAbonnementEnCours" (click)="service.modificationAbonnementEnCours = true;ajouterAbonnement(service.reference, service.nom, !service.abonnementActif)">
                  <mat-icon>{{(service.abonnementActif)?'email':'mail_outline'}}</mat-icon>
                </button>
              </div>
              <mat-spinner *ngIf="service.modificationAbonnementEnCours" diameter="20"></mat-spinner>
            </mat-cell>
          </ng-container>
          <!-- Colonne Nom du service -->
          <ng-container cdkColumnDef="nom">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Nom </mat-header-cell>
            <mat-cell *cdkCellDef="let service" (click)="rediriger(service)"> {{service.nom |
              titlecase}}
            </mat-cell>
          </ng-container>
          <!-- Colonne Dernière mise à jour -->
          <ng-container cdkColumnDef="maj">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Enregistré le </mat-header-cell>
            <mat-cell *cdkCellDef="let service" (click)="rediriger(service)">
              {{service.dateMajIndisponibilite ? (service.dateMajIndisponibilite | date:'dd/LL/yyyy' | titlecase) : " -
              "}}
            </mat-cell>
          </ng-container>
          <!-- Colonne Statut -->
          <ng-container cdkColumnDef="statut">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Statut </mat-header-cell>
            <mat-cell *cdkCellDef="let service" (click)="rediriger(service)">
              <img [src]="recupererImageMeteo(service.statutIndisponibilite)" />
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="colonnes; sticky: true"></mat-header-row>
          <mat-row matRipple *cdkRowDef="let row; columns: colonnes;"></mat-row>
        </mat-table>
      </ion-col>
    </ion-row>

  </mat-card-content>
</mat-card>