<div class="ion-text-center ion-align-items-center notation-container" padding-top>
  <!-- Libelle de la note -->
  <h6>{{typeNoteMap.get(avis.typeNote)}}</h6>

  <!-- Bouton de sélection de la note -->
  <div class="ligne-etoiles">
    <button mat-icon-button [color]="couleur" *ngFor="let idNote of tableauNotes" [id]="'star_'+idNote" (click)="selectionnerNote(idNote+1)"
      [disabled]="!avis.estActif">
      <mat-icon>{{(avis.note >= idNote + 1) ? 'star' : 'star_border'}}</mat-icon>
    </button>
  </div>

  <!-- Date de prochaine sélection de la note -->
  <ion-row class="ion-align-items-center prochaine-note" *ngIf="!avis.estActif">
    <ion-col class="ion-text-center ion-no-padding">
      Notation disponible à partir du {{avis.dateProchaineNote | date:'dd/MM/yyyy'}}
    </ion-col>
  </ion-row>

  <!-- Moyenne de la note -->
  <ion-row *ngIf="avis.moyenne && avis.nombreVotant">
    <!-- DecimalPipe 1 => 1 décimale avant la virgule, et 0 à 1 décimale après la virgule -->
    <ion-col class="ion-text-center">Moy. <strong>{{ avis.moyenne | number:'1.0-1':'fr'}}/{{noteMax}}</strong>
      ({{avis.nombreVotant}} votants)</ion-col>
  </ion-row>
</div>