import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { RestUtilsService } from './services/utilitaires/rest-utils.service';
import { Observable } from 'rxjs';

/**
 * Composant interceptant les appels HTTP pour modifier les formats de date et ne pas envoyer des dates en UTC.
 */
@Injectable()
export class JsonInterceptorService implements HttpInterceptor {

  constructor(private restUtilsService: RestUtilsService) { }

  /**
   * Interception pour modifier les formats de date et ne pas envoyer des dates en UTC.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.body) {
      const obj = req.body;
      for (const attr in obj) {
        if (obj[attr] && obj[attr] instanceof Date) {
          const d: Date = (obj[attr] as Date);
          obj[attr] = this.restUtilsService.formaterDateHeurePourUrl(d);
        }
      }
    }
    return next.handle(req);
  }

}
