import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestUtilsService } from './utilitaires/rest-utils.service';
import { environment } from '../../environments/environment';
import { IndisponibiliteDuJour, Indisponibilite, IndisponibiliteDto, AlerteDto } from '../model/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeteoService {

  private urlIndispo = environment.url + '/indisponibilite';

  constructor(private http: HttpClient, private restUtils: RestUtilsService) { }

  /**
   * Création d'une indisponibilité
   * @param indispo Indisponibilité à créer
   */
  creerIndisponibilite(indispo: Indisponibilite): Observable<IndisponibiliteDto> {
    return this.http.post<IndisponibiliteDto>(this.urlIndispo, indispo, this.restUtils.creerHeaderPostWithAccessToken());
  }

  /**
   * Recherche de l'historique des indisponibilités
   */
  rechercherHistorique(refService: string): Observable<Indisponibilite[]> {
    return this.http.get<Indisponibilite[]>(this.urlIndispo + '/historique/' + refService, this.restUtils.creerHeaderWithAccessToken());
  }

  /**
   * Diffusion d'une indisponibilité
   * @param indispo Indisponibilité à diffuser
   */
  diffuserIndisponibilite(refIndispo: string, nomService: string): Observable<void> {
    return this.http.put<void>(this.urlIndispo + '/' + refIndispo + '/diffuser', null,
      this.restUtils.creerHeaderPostWithAccessToken());
  }

  /** Recuperation d'une liste indispo dto d'un service sur chaque jour du mois de la date passé */
  recupererStatutIndispoParJour(date: Date, refService: string): Observable<IndisponibiliteDuJour[]> {
    return this.http.get<IndisponibiliteDuJour[]>(environment.url + '/service/' + refService + '/indisponibilite?date='
      + this.restUtils.formaterDatePourUrl(date),
      this.restUtils.creerHeader());
  }

  /** Recuperation d'une liste indispo dto des services en favoris sur chaque jour du mois de la date passé */
  recupererStatutIndispoParJouParFavoris(date: Date): Observable<IndisponibiliteDuJour[]> {
    return this.http.get<IndisponibiliteDuJour[]>(environment.url + '/favoris/indisponibilite?date='
      + this.restUtils.formaterDatePourUrl(date),
      this.restUtils.creerHeader());
  }

  /** Recupération du nombre de jours par an pour chaque statut pour un service */
  recupererNombreIndisponibiliteParStatut(refService: string): Observable<Map<string, number>> {
    return this.http.get<any>(environment.url + '/service/' + refService + '/indisponibilite/annuel', this.restUtils.creerHeader())
      .pipe(map((json) => this.restUtils.formaterJsonToMap(json)));
  }

  /** Récupération du nombre d'alerte sur un service */
  recupererNombreAlerte(refService: string): Observable<AlerteDto> {
    return this.http.get<AlerteDto>(environment.url + '/service/' + refService + '/alerte', this.restUtils.creerHeader());
  }

  /** Récupération du nombre d'alerte sur un service */
  notifierAlerte(refService: string): Observable<void> {
    return this.http.post<void>(environment.url + '/service/' + refService + '/alerte', null, this.restUtils.creerHeaderPost());
  }
}
