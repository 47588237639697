import { Component, Inject  } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { IonicModule } from '@ionic/angular';

// Variable par environnement
import { environment } from '../environments/environment';

import { EnteteComponent } from './entete/entete.component';
import { DonneesEnCours } from './services/donnees-en-cours';
import { Router, NavigationEnd } from '@angular/router';
import { AuthentificationService } from './services/authentification/authentification.service';

// /** Configuration de OpenAPI */
// // @see https://manfredsteyer.github.io/angular-oauth2-oidc/docs/index.html
// export const authConfig: AuthConfig = {

//     // à configurer dans les redirectURI autorisées
//     // dans https://console.cloud.google.com/iam-admin/serviceaccounts
//     // ?authuser=0&organizationId=375698829007&project=fr-water-vedif-meteo-dev
//     clientId: environment.client_id,
//     // à configurer dans les redirectURI autorisées
//     // dans https://console.developers.google.com/apis/credentials?project=fr-water-vedif-meteo-dev
//     redirectUri: environment.redirectUri,

//     // Configuration propre à Google à ne pas changer - DEBUT
//     scope: 'openid profile email',
//     requestAccessToken: true,
//     issuer: 'https://accounts.google.com',
//     // ur1 de la page du silent refresh
//     silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
//     silentRefreshTimeout: 20000,
//     strictDiscoveryDocumentValidation: false,
//     showDebugInformation: false,
//     sessionChecksEnabled: false,
//     sessionCheckIntervall: 3000
//     // Configuration propre à Google à ne pas changer - FIN

// };

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})

export class AppComponent {

  /** Constructeur permettant l'injection de composants */
  constructor(
    private platform: Platform,
    // private oauthService: OAuthService,
    private router: Router, private authentificationService: AuthentificationService, private donneesEnCours: DonneesEnCours) {
    // Initialisation IONIC
    this.initializeApp();
    // Initialisation OpenAPI
    this.configureWithNewConfigApi();
  }
  // private configureWithNewConfigApi() {
  //     this.oauthService.configure(authConfig);
  //     this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  //     // Permet le refresh automatique du token sans raffraichir la page
  //     this.oauthService.setupAutomaticSilentRefresh();
  //     this.oauthService.loadDiscoveryDocumentAndLogin().then(() => this.donneesEnCours.chargerUtilisateur());
  // }

  /** Initialisation OpenAPI et, une fois connecté, appel à recupererUtilisateur */
  private configureWithNewConfigApi(): void {
    this.authentificationService.chargerToken().subscribe();
    // on vérifie si co
    this.authentificationService.estConnecte().subscribe(estConnecte => {
      if (!estConnecte) {
        //   on lance l'auth si pas co
        this.authentificationService.seConnecter().subscribe();
      } else {
        // on charge les données
        this.donneesEnCours.chargerUtilisateur();
      }
    });
  }

  /** Initialisation IONIC  */
  private async initializeApp() {
    await this.platform.ready();
    await SplashScreen.hide();
  }
}
