<ion-header>
  <ion-toolbar color="primary" class="toolbar ion-no-padding ion-no-margin">
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-justify-content-evenly ion-no-padding">

        <!-- Logo -->
        <ion-col class="d-flex ion-align-items-center" size-md="auto" size-sm="1" size-xs="0" 
          [ngClass]="{'custom-styles': true, 'hide-logo': !showLogo}" [ngStyle]="dynamicStyles"
          [matTooltip]="numCommit" [matTooltipShowDelay]="4000" [matTooltipHideDelay]="500">
          <img (click)="retourAccueil()" src="./../../assets/logo/logoVeolia.png" class="logo">
        </ion-col>

        <!-- Texte appli -->
        <ion-col class="d-flex ion-align-items-center padding-left-12">
          <div class="texte-meteo margin" [class.texte-meteo--active]="searchVisible">
            <div class="texte-meteo-close">Météo des services DSI</div>
          </div>
        </ion-col>

        <!-- Partie "fonctionnelle" du header-->
        <ion-col class="margin">
          <div id="div-input" class="margin-right-12 d-flex align-items-center">

            <!-- Input avec autocomplétion-->
            <mat-form-field class="mat-search_field" [@slideInOut]="searchVisible" color="accent">
              <mat-label>Rechercher un service</mat-label>
              <input #input id="input" matInput type="search" [matAutocomplete]="auto" [formControl]="serviceCtrl"
                placeholder="">
              <mat-error>Merci de renseigner un nom de service existant</mat-error>
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let service of services | async" [value]="service.nom" (onSelectionChange)="rediriger(service)">
                  {{service.nom}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="mat-search_icons" [class.mat-search_icons--active]="searchVisible">
              <mat-icon class="mat-search_icon-close" (click)="fermerAnimation()" matRipple>close</mat-icon>
              <mat-icon matTooltipPosition="left" matTooltip="Rechercher un service" class="mat-search_icon-search"
                (click)="ouvrirAnimation()" matRipple>search</mat-icon>
            </div>

            <!-- Icone utilisateur -->
            <div>
              <mat-icon *ngIf="!utilisateur" matRipple>account_circle</mat-icon>
              <img class="img-utilisateur" *ngIf="utilisateur" [src]="utilisateur.image" matTooltipPosition="left"
                [matTooltip]="utilisateur.prenom + ' ' + utilisateur.nom">
            </div>
          </div>
          <div *ngIf="utilisateur" class="margin-right-12 ion-text-right cacher-sm">
            <span class="libelleNom">Bonjour, {{utilisateur.prenom}} {{utilisateur.nom}}</span>
            <div class="libelleDirection">
              <span matTooltip="Votre direction">{{utilisateur.direction.nomReel}}</span>
              <span> - </span>
              <span matTooltip="Votre unité de rattachement">{{utilisateur.uniteRattachement}}</span>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>
