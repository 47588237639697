import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras } from '@angular/router';
// import { OAuthService } from 'angular-oauth2-oidc';
import { AuthentificationService } from './authentification.service';
import { Observable } from 'rxjs';

/** Garde permettant de protéger une route contre l'accès d'un utilisateur non connecté. */
@Injectable()
export class AuthGuard implements CanActivate {

  /** Constructeur avec injection */
  constructor(private router: Router,
    // private oauthService: OAuthService,
    private authentificationService: AuthentificationService) { }

  /**
   * Garde contre l'accès d'un utilisateur non connecté (renvoi à la route de connexion si nécessaire)
   *
   * @see https://github.com/manfredsteyer/angular-oauth2-oidc/issues/221#issuecomment-384379611
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    // // Si la page a été rechargée avec un utilisateur déjà connecté
    // if (this.oauthService.hasValidIdToken()) {
    //   return Promise.resolve(true);
    // }

    // // Sinon
    // return this.oauthService.loadDiscoveryDocumentAndTryLogin()
    //   .then(_ => this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken())
    //   .then(valid => valid);
    // }

    return this.authentificationService.estConnecte();
  }
}
