import { Component, OnInit, Inject } from '@angular/core';
import { MeteoService } from '../../services/meteo.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlerteDto, Service, TYPE_MESSAGE, CODE_RESPONSABLE } from '../../model/model';
import { DonneesEnCours } from '../../services/donnees-en-cours';
import { ReferentielService } from '../../services/referentiel.service';

@Component({
  selector: 'app-dialog-alert',
  templateUrl: './dialog-alert.component.html',
  styleUrls: ['./dialog-alert.component.scss']
})
export class DialogAlertComponent implements OnInit {

  alerteDto: AlerteDto;

  interlocuteurs: string[];

  constructor(private meteoService: MeteoService, private donneesEnCours: DonneesEnCours,
    private referentielService: ReferentielService, @Inject(MAT_DIALOG_DATA) public service: Service) { }

  ngOnInit() {
    // recupère le nombre d'alerte effectué dans l'heure sur le service
    // Si déjà deux envois, on notifi directement (java gère l'envoi des mails)
    this.meteoService.recupererNombreAlerte(this.service.reference).subscribe(nbr => {
      this.alerteDto = nbr;
      if (this.alerteDto.nombreAlerte > 1) {
        this.meteoService.notifierAlerte(this.service.reference);
      }
    });
    // si pas d'interlocuteurs de chargé, on les charges
    if (!this.service.documentations) {
      this.referentielService.recupererServiceAvecDescription(this.service.reference).subscribe((s) => {
        this.service = s;
        this.genererInterlocuteur();
      });
    } else {
      this.genererInterlocuteur();
    }
  }

  /** envoyer une notification */
  envoyer() {
    this.meteoService.notifierAlerte(this.service.reference).subscribe(() =>
      this.donneesEnCours.ajouterMessageSnackBar('Vos interlocuteurs ont été prévenus de l\'incident', TYPE_MESSAGE.succes)
    );
  }

  /** afficher les interlocuteurs */
  private genererInterlocuteur() {
    if (this.service.documentations) {
      this.interlocuteurs = this.service.documentations
        .filter((doc) => doc.categorie === CODE_RESPONSABLE).map(d => d.information);
    }
  }

}
