import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-vide',
  templateUrl: './dialog-vide.component.html',
  styleUrls: ['./dialog-vide.component.scss']
})
export class DialogVideComponent {

  constructor(private dialogRef: MatDialogRef<DialogVideComponent>,
    @Inject(MAT_DIALOG_DATA) public texte: string) { }

}
