export class Utilisateur {
    idGoogle: string;
    reference: string;
    email: string;
    nom: string;
    prenom: string;
    fonction: string;
    image: string;
    direction: Direction;
    flagContributeur: boolean;
    flagSuperviseur: boolean;
    uniteRattachement: string;
    numTel: string;
}

export class Direction {
    reference: string;
    nom: string;
    nomReel: string;
    documentations: Documentation[];
}

export class Service {
    reference: string;
    nom: string;
    trigramme: string;
    description: string;
    documentations: Documentation[];
}

export class ServiceDto {
    reference: string;
    nom: string;
    trigramme: string;
    refDirection: string;
}

export class Documentation {
    reference: string;
    categorie: number;
    libelle: string;
    information: string;
    dateMaj: Date;
    service: Service;
    direction: Direction;
}

export class Avis {
    reference: string;
    dateCreation: Date;
    note: number;
    typeNote: string;
}

export class AvisDto {
    refAvis: string;
    typeNote: string;
    note: number;
    dateProchaineNote: Date;
    estActif: boolean;
    moyenne: number;
    nombreVotant: number;
}

export class Indisponibilite {
    reference: string;
    service: Service;
    commentaire: string;
    statut: string;
    dateDebut: Date;
    dateFin: Date;
    aDiffuser = false;
    utilisateur: Utilisateur;
    dateDemandeDiffusion: Date;
}

export class IndisponibiliteDto {
    reference: string;
    refService: string;
    refDirection: string;
    refFavoris: string;
    dateDebut: Date;
    dateFin: Date;
}

export class IndisponibiliteDuJour {
    statut: string;
    dateIndispo: Date;
    commentaire: string;
}

export class ServiceAvecStatutDto {
    reference: string;
    refDirection: string;
    nom: string;
    statutIndisponibilite: string;
    dateMajIndisponibilite: string;
    abonnementActif: boolean;
    refFavoris: string;
    modificationFavorisEnCours = false;
    modificationAbonnementEnCours = false;
}

export class NombreNotificationParMois {
    mois: Date;
    nombreNotificationArret: number;
    nombreNotificationDegrade: number;
    nombreNotificationEnService: number;
}

export class FichierGraphDto {
    nom: string;
    extension: string;
    fichierEnBytes: string;
}

export class ServiceGraphiqueDto {
    urlGraph1: string;
    urlGraph2: string;
    urlGraph3: string;
    fichierGraph1: FichierGraphDto;
    fichierGraph2: FichierGraphDto;
    fichierGraph3: FichierGraphDto;
}

export class AlerteDto {
    nombreAlerte: number;
    nombreMinutesResetAlerte: number;
}

export class DataGraphique {
    mapData: Map<string, number>;
    nomData: string;
    constructor(mapData: Map<string, number>, nomData: string) {
        this.mapData = mapData;
        this.nomData = nomData;
    }
}

export class Supervision {
    nom: string;
    etat: string;
    commentaire: string;
    adresseMail: string;
    horodatage: Date;
}

export const STATUT_SERVICE = [
    { value: 'ARRET', text: 'A l\'arrêt' },
    { value: 'DEGRADE', text: 'Dégradé' },
    { value: 'EN_SERVICE', text: 'En service' }
];

export const STATUT_SUPERVISION = [
    { value: 'KO majeur', text: 'KO majeur' },
    { value: 'KO mineur', text: 'KO mineur' },
    { value: 'OK', text: 'OK' }
];

export enum TYPE_MESSAGE {
    erreur = 'ERREUR',  // Rouge
    avertissement = 'AVERTISSEMENT',    // Orange
    succes = 'SUCCESS' // Vert
}

export const CODE_RESPONSABLE = 0;
export const CODE_TUTORIEL = 1;
export const CODE_FAQ = 2;
export const CODE_FONCTION = 3;
/**
 * Récupération du lien vers le logo du statut
 * @param statut value d'un statut de la constante STATUT_SERVICE
 */
export function RECUPERER_IMAGE_METEO(statut: string): string {
    if (statut === STATUT_SERVICE[0].value) {
        return 'assets/icon/statutIndisponible.png';
    } else if (statut === STATUT_SERVICE[1].value) {
        return 'assets/icon/statutPartiel.png';
    } else {
        return 'assets/icon/statutDisponible.png';
    }
}

/**
 * Récupération du lien vers le logo du statut
 * @param statut value d'un statut de la constante STATUT_SUPERVISION
 */
export function RECUPERER_IMAGE_METEO_SUPERVISION(statut: string): string {
    if (statut === STATUT_SUPERVISION[0].value) {
        return 'assets/icon/statutIndisponible.png';
    } else if (statut === STATUT_SUPERVISION[1].value) {
        return 'assets/icon/statutPartiel.png';
    } else {
        return 'assets/icon/statutDisponible.png';
    }
}
