import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { TYPE_MESSAGE } from '../../model/model';

@Component({
  selector: 'app-snack-bar-message',
  templateUrl: './snack-bar-message.component.html',
  styleUrls: ['./snack-bar-message.component.scss']
})
export class SnackBarMessageComponent implements OnInit {

  private rouge = '#ef5350';
  private iconeRouge = 'error';
  private orange = '#ffca28';
  private iconeOrange = 'warning';
  private vert = '#98ee99';
  private iconeVert = 'check_circle';
  couleur = this.vert;
  icone = this.iconeVert;
  message = 'Une erreur est survenue. Merci de réessayer utltérieurement.';

  constructor(private snackBarRef: MatSnackBarRef<SnackBarMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string, codeErreur: number, typeMessage: TYPE_MESSAGE }) { }

  ngOnInit() {
    // si message passé, on remplace le message par defaut
    if (this.data.message) {
      this.message = this.data.message;
    }
    // on fonction du code erreur on modifi la couleur
    if (TYPE_MESSAGE.erreur === this.data.typeMessage) {
      this.couleur = this.rouge;
      this.icone = this.iconeRouge;
    } else if (TYPE_MESSAGE.avertissement === this.data.typeMessage) {
      this.couleur = this.orange;
      this.icone = this.iconeOrange;
    }
    // si un code d'erreur est passé, on l'affiche
    if (this.data.codeErreur) {
      this.message += ' (Erreur ' + this.data.codeErreur + ').';
    }
  }

  /** ferme la snackbar */
  fermer() {
    this.snackBarRef.dismiss();
  }
}
