<ion-content>
  <!-- Ecran mon bulletin meteo content -->
  <ion-grid>
    <!-- Zone de contribution -->
    <ion-row *ngIf="utilisateurEstContributeur">
      <ion-col>
        <app-contribution></app-contribution>
      </ion-col>
    </ion-row>

    <!-- Ligne de titre -->
    <mat-card class="ligne-titre">
      <ion-row class="ion-align-items-center ligne-titre">
        <ion-col class="ion-no-padding">
          <ion-title>
            <b><span class="bulletin">Mon bulletin météo : </span>{{ dateCalendrier | date:'EEEE dd LLLL yyyy' | titlecase }}</b>
          </ion-title>
        </ion-col>

        <ion-col size="auto" class="ion-no-padding col-icon">
          <!-- Interlocuteurs -->
          <!-- Toujours DSI à la demande de François -->
          <app-liens [documentations]="documentations" [nom]="direction ? 'DSI' : null"></app-liens>
          <!-- Cartographie -->
          <app-cartographie></app-cartographie>
          <app-bouton-graph-stats></app-bouton-graph-stats>
          <app-bouton-tuto></app-bouton-tuto>
        </ion-col>
      </ion-row>
    </mat-card>

    <ion-row class="ion-align-items-stretch">
      <!-- Calendrier -->
      <ion-col size-xs="12" size-md="6" size-xl="3">
        <app-calendrier></app-calendrier>
      </ion-col>

      <!-- Mes services favoris -->
      <ion-col size-xs="12" size-md="6" size-xl="4.5">
        <app-mes-services-favoris></app-mes-services-favoris>
      </ion-col>

      <!-- Directions (services par direction) -->
      <ion-col size-xs="12" size-md="6" size-xl="4.5">
        <app-services-par-direction></app-services-par-direction>
      </ion-col>

      <!-- Supervision journaliere de la DSI -->
      <ion-col size-xs="12" size-md="6" size-xl="4.5" *ngIf="utilisateurEstSuperviseur">
        <app-supervision-journaliere-dsi></app-supervision-journaliere-dsi>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
