<!-- Problème d'indentation en ligne 5 à cause du caractère "<" -->
<div mat-dialog-title class="couleur">
  <div class="titre-alerte">
    <img class="hauteur-fixe" src="assets/icon/alerte.png">
    <span>
      <span>Signalement de dysfonctionnement </span>
      <span>{{service.nom.length < 21 ? service.nom : (service.nom | slice:0:20) + '...' }} </span>
    </span>
  </div> <button mat-icon-button [mat-dialog-close] class="close">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="couleur">
  <div *ngIf="alerteDto && !(alerteDto.nombreAlerte > 1 )">Vous allez transmettre
    un mail de demande de controle du service {{service.nom}} aux personnes suivantes :
    <div *ngFor="let interlocuteur of interlocuteurs"> - {{interlocuteur.split('|')[0]}}</div>
    <br />
    Confirmez-vous cet envoi ?
    <br />
    Merci.
  </div>
  <div *ngIf="alerteDto && alerteDto.nombreAlerte > 1">Le dysfonctionnement du service {{service.nom}} a été
    signalé par {{alerteDto.nombreAlerte ? alerteDto.nombreAlerte : 0}}
    alerte(s) utilisateur(s) dans les {{alerteDto.nombreMinutesResetAlerte}} dernières minutes.
    <br />
    Vos interlocuteurs sont prévenus et contrôlent actuellement le fonctionnement de ce service.
    <br />
    Merci de votre compréhension.</div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]>Annuler</button>
  <button mat-button color="primary" [disabled]="alerteDto && alerteDto.nombreAlerte > 1" (click)="envoyer()"
    [mat-dialog-close]>Envoyer</button>
</div>