import { Component, Input } from '@angular/core';
import { RECUPERER_IMAGE_METEO, STATUT_SERVICE } from '../../model/model';

@Component({
  selector: 'app-compteur-par-statut',
  templateUrl: './compteur-par-statut.component.html',
  styleUrls: ['./compteur-par-statut.component.scss']
})
export class CompteurParStatutComponent {

  /** Compteur des différents statut */
  @Input() compteurArret: number;
  @Input() compteurDegrade: number;
  @Input() compteurEnService: number;

  /** Fonction de récupération des icones des statut */
  recupererImageMeteo = RECUPERER_IMAGE_METEO;

  /** Liste des statuts */
  statutService = STATUT_SERVICE;

}
