import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
// import { OAuthService } from 'angular-oauth2-oidc';
import { catchError } from 'rxjs/operators';
import { DonneesEnCours } from '../donnees-en-cours';
import { TYPE_MESSAGE } from '../../model/model';
import { environment } from '../../../environments/environment';
import { AuthentificationService } from '../authentification/authentification.service';

/** Classe utilitaire pour les appels REST */
@Injectable({ providedIn: 'root' })
export class RestInterceptor implements HttpInterceptor {

    /** Constructeur pour l'injection de composant */
    constructor(
        // private oauthService: OAuthService,
        private donneesEnCours: DonneesEnCours, private authentificationService: AuthentificationService) { }

    // /** Presence d'un token valide */
    // private estConnecte(): boolean {
    //     return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
    // }

    /** Intercept les requetes http */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Si utilisateur pas co, pas de requetes
        if (request.url.includes(environment.url) && !this.authentificationService.estConnecte()) {
            this.donneesEnCours.ajouterMessageSnackBar('Votre session a expirée, merci de vous reconnecter', TYPE_MESSAGE.avertissement);
            window.location.reload();
            return of();
        }

        return next.handle(request).pipe(catchError((error, caught) => {
            // intercept the respons error and displace it to the console
            this.handleError(error);
            return of(error);
        }) as any);
    }

    /** Gestionnnaire des erreurs REST */
    public handleError(error: HttpErrorResponse) {
        // A client-side or network error occurred. Handle it accordingly.
        let erreur: string = null;
        let typeErreur = TYPE_MESSAGE.erreur;
        let codeErreur: number = null;
        console.log(error.error);
        if (typeof error.error === 'string') {
            erreur = error.error;
            codeErreur = error.status;
            if (error.error === 'Token expiré' || error.error === 'Token incorrecte') {
                this.authentificationService.supprimerToken();
            }
        } else if (!(error.error instanceof ErrorEvent)) {
            // En cas d'erreur 401
            codeErreur = error.status;

            if (codeErreur === 401) {
                this.authentificationService.supprimerToken();
                // localStorage.removeItem('ACCESS_TOKEN');
                // localStorage.removeItem('TOKEN');
                typeErreur = TYPE_MESSAGE.avertissement;
            }
        }
        // Ajout d'une erreur
        this.donneesEnCours.ajouterMessageSnackBar(erreur, typeErreur, codeErreur);

        // return an ErrorObservable with a user-facing error message
        return throwError('Une erreur est arrivée. Si le problème persiste, merci de contacter l\'administrateur');
    }
}
