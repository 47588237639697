<!-- Composant de masquage de la zone de contribution -->
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title class="title_diffusion">Mon espace de diffusion</mat-panel-title>
  </mat-expansion-panel-header>

  <ion-row class="display-block-column">

    <!-- Formulaire de saisie d'une indisponibilité -->
    <form #contributionForm="ngForm">

      <ion-row>
        <!-- Input avec autocomplétion du service -->
        <ion-col size="12" size-md="4">
          <mat-form-field class="display-block-column">
            <input #input matInput placeholder="Rechercher un service" type="search" [matAutocomplete]="auto"
              [formControl]="serviceCtrl" required>
            <mat-error>Merci de renseigner un nom de service existant</mat-error>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let service of services | async" [value]="service.nom" (onSelectionChange)="selectionerService(service)">
                {{service.nom}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </ion-col>

        <!-- Button-toggle-group de sélection du statut -->
        <ion-col size="12" size-md="6" size-xl="4" class="text-md-start text-xl-center padding-vertical ion-text-center">
          <mat-button-toggle-group [(ngModel)]="indispo.statut" required name="statut">
            <mat-button-toggle [value]="statut.value" *ngFor='let statut of statutsService' (click)="changerContenuMail()">
              <img class="img-statut" [src]="recupererImageMeteo(statut.value)"><span class="selection-statut">{{statut.text}}</span></mat-button-toggle>
          </mat-button-toggle-group>
        </ion-col>

        <!-- Date de début -->
        <ion-col size="6" size-md="3" size-xl="2">
          <mat-form-field class="block">
            <input matInput [matDatepicker]="dateDebut" [max]="indispo.dateFin" [(ngModel)]="indispo.dateDebut" name="dateDebut"
              placeholder="Du" required autocomplete="off" (dateChange)="changerContenuMail()">
            <mat-datepicker-toggle matSuffix [for]="dateDebut"></mat-datepicker-toggle>
            <mat-datepicker #dateDebut disabled="false"></mat-datepicker>
          </mat-form-field>
        </ion-col>

        <!-- Date de fin -->
        <ion-col size="6" size-md="3" size-xl="2">
          <mat-form-field class="block">
            <input matInput [matDatepicker]="dateFin" [min]="indispo.dateDebut" [(ngModel)]="indispo.dateFin" name="dateFin"
              placeholder="Au" required autocomplete="off" (dateChange)="changerContenuMail()">
            <mat-datepicker-toggle matSuffix [for]="dateFin"></mat-datepicker-toggle>
            <mat-datepicker #dateFin disabled="false"></mat-datepicker>
          </mat-form-field>
        </ion-col>

      </ion-row>

      <!-- Textarea -->
      <ion-row>
        <ion-col>
          <mat-form-field class="display-block-column" appearance="outline">
            <textarea class="contribution_text_area" matInput maxlength="4000" placeholder="Ecrire un commentaire..." [(ngModel)]="indispo.commentaire"
              name="commentaire" matTextareaAutosize matAutosizeMinRows=6 required (keyup)="bloquerChangementContenuMail($event)"></textarea>
          </mat-form-field>
        </ion-col>
      </ion-row>

      <!-- Bouton Historique,  Enregistrer et Enregistrer/Diffuser -->
      <ion-row>
        <ion-col size="6" size-md="4" size-lg="2" offset-md="0" offset-lg="2">
          <button class="block" mat-raised-button type="button" color="accent" (click)="ouvrirDialogHistorique()">Historique</button>
        </ion-col>
        <ion-col size="6" size-md="4" size-lg="2">
          <button class="block" mat-raised-button type="button" color="accent" (click)="viderFormulaire()">Vider Formulaire</button>
        </ion-col>
        <ion-col size="12" size-md="4" size-lg="2">
          <button class="block" mat-raised-button color="primary" (click)="enregistrer()" [disabled]="!contributionForm.form.valid || !serviceCtrl.valid">Enregistrer</button>
        </ion-col>
        <ion-col size="12" size-md="4" size-lg="2">
          <button class="block" mat-raised-button color="primary" (click)="enregistrerEtDiffuser()"
            [disabled]="!contributionForm.form.valid || !serviceCtrl.valid">Enregistrer et diffuser</button>
        </ion-col>
      </ion-row>

    </form>

  </ion-row>

</mat-expansion-panel>
