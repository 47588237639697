<mat-card>
  <!-- Titre de la card -->
  <mat-card-subtitle class="ion-text-center">
    Supervisions journalières de la Direction des Systèmes d'Informations
  </mat-card-subtitle>
  <!-- Content de la card -->
  <mat-card-content>

    <!-- Ligne des compteurs par statut -->
    <app-compteur-par-statut [compteurArret]="compteurArret" [compteurDegrade]="compteurDegrade"
      [compteurEnService]="compteurEnService"></app-compteur-par-statut>

    <!-- Tableau des supervisions -->
    <ion-row>
      <ion-col>
        <mat-table [dataSource]="supervision" matSort>
          <!-- Colonne Préférences -->
          <ng-container cdkColumnDef="prefs">
            <mat-header-cell *cdkHeaderCellDef>
              <!-- Bouton pour cocher/decocher abonné dans le header -->
              <div [class]="bouton-coche">
                <button mat-icon-button>
                  <mat-icon>email</mat-icon>
                </button>
              </div>
            </mat-header-cell>
            <mat-cell *cdkCellDef="let supervision">
              <!-- Bouton abonnement-->
              <div [class]="bouton-coche">
                <button mat-icon-button (click)="envoyerMail(supervision.adresseMail)">
                  <mat-icon>email</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>
          <!-- Colonne Nom -->
          <ng-container cdkColumnDef="nom">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Nom
            </mat-header-cell>
            <mat-cell *cdkCellDef="let supervision"> {{supervision.nom | titlecase}}
            </mat-cell>
          </ng-container>
          <!-- Colonne Dernière mise à jour -->
          <ng-container cdkColumnDef="maj">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Enregistré le
            </mat-header-cell>
            <mat-cell *cdkCellDef="let supervision" 
            [style.color]="!verifierSiDateJour(supervision.horodatage) ? '#d32f2f' : ''">
              {{supervision.horodatage ? (supervision.horodatage | date:'dd/LL/yyyy' | titlecase) : " - "}}
            </mat-cell>
          </ng-container>
          <!-- Colonne Statut -->
          <ng-container cdkColumnDef="statut">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Statut
            </mat-header-cell>
            <mat-cell *cdkCellDef="let supervision" [matTooltip]="supervision.commentaire"
            [matTooltipDisabled]="supervision.etat==='OK' || supervision.etat===''">
            <div *ngIf="supervision.etat!==''">
              <img [src]="recupererImageMeteoSupervision(supervision.etat)" />
            </div>
            <div *ngIf="supervision.etat===''">
              -
            </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *cdkHeaderRowDef="colonnes; sticky: true"></mat-header-row>
          <mat-row matRipple *cdkRowDef="let row; columns: colonnes;"></mat-row>
        </mat-table>
      </ion-col>
    </ion-row>

  </mat-card-content>
</mat-card>