import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ReferentielService } from '../services/referentiel.service';
import { ServiceAvecStatutDto, RECUPERER_IMAGE_METEO, Service, Documentation } from '../model/model';
import { DonneesEnCours } from '../services/donnees-en-cours';
import { MatDialog } from '@angular/material/dialog';
import { DialogAlertComponent } from '../commun/dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-mon-service',
  templateUrl: './mon-service.component.html',
  styleUrls: ['./mon-service.component.scss']
})
export class MonServiceComponent implements OnInit {

  /** Référence du service dont les informations sont chargées */
  refService: string;
  /** Date de recherche des informations de météo (initialisée à la date du jour)  */
  date: Date = new Date();
  /** Dto du service contenant les informations sur la journée date */
  serviceDto: ServiceAvecStatutDto = new ServiceAvecStatutDto();
  /** Fonction de récupération de l'icone du statut */
  recupererImageMeteo = RECUPERER_IMAGE_METEO;
  /** Services avec les documentations */
  serviceAvecDoc: Service;
  /** Documentation pour le service */
  documentations: Documentation[];

  constructor(private router: Router, private route: ActivatedRoute, private referentielService: ReferentielService,
    private donneesEnCours: DonneesEnCours, private dialogAlertComponent: MatDialog) { }

  ngOnInit() {
    // Référence du service récupéré dans l'url
    this.refService = this.route.snapshot.paramMap.get('refService');

    // Abonnement aux modifications éventuelles
    this.donneesEnCours.refServiceChangementAbonnement.subscribe(objet => {
      if (objet.refService === this.refService) {
        this.serviceDto.abonnementActif = objet.statut;
        this.serviceDto.modificationAbonnementEnCours = false;
      }
    });

    this.donneesEnCours.refServiceChangementFavoris.subscribe(refServiceReponse => {
      if (this.refService === refServiceReponse) {
        this.chargerDonnees();
      }
    });
    this.router.events.subscribe((e) => {
      // test sur l'URL car le subscribe se déclenche quand on arrive sur l'écran BulletinMeteo
      if (e instanceof NavigationEnd && e.url.startsWith('/mon-service/')) {
        this.refService = e.url.substring(13);
        this.chargerDonnees();
      }
    });

    // Chargement des données
    this.chargerDonnees();
  }

  /** Chargement des informations du service */
  chargerDonnees() {
    this.referentielService.chargerMonService(this.refService, this.date).subscribe((serviceRetour) => this.serviceDto = serviceRetour);
    this.referentielService.recupererServiceAvecDescription(this.refService).subscribe((s) => {
      this.serviceAvecDoc = s;
      this.documentations = this.serviceAvecDoc.documentations;
    });
  }

  /** Gestion des favoris */
  ajouterFavoris(refService: string, nomService: string, statut: boolean) {
    let fav = 'Favoris';
    if (!statut) {
      fav = 'Défavoris';
    }
    this.donneesEnCours.changerFavoris(refService, statut);
  }

  /** Gestion des abonnements */
  ajouterAbonnement(refService: string, nomService: string, statut: boolean) {
    let abo = 'Abonnement';
    if (!statut) {
      abo = 'Désabonnement';
    }
    this.donneesEnCours.changerAbonnement(refService, statut);
  }

  alerte(): void {
    this.dialogAlertComponent.open(DialogAlertComponent, {
      data: this.serviceAvecDoc,
      maxWidth: '45%'
    });
  }
}
