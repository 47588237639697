<mat-card>
    <!-- Titre de la card -->
    <mat-card-subtitle class="ion-text-center">
        <mat-icon>how_to_vote</mat-icon>DONNEZ VOTRE AVIS
    </mat-card-subtitle>
    <mat-card-content>
        <ion-row *ngIf="listeAvis">

            <!-- Affichage des 3 notes (Ergonomie, Rapidité et Formation) -->
            <ion-col size-xs="12" size-sm="4" *ngFor="let avis of listeAvis" class="col-notation">
                <app-notation [avis]="avis"></app-notation>
            </ion-col>

        </ion-row>
    </mat-card-content>
</mat-card>