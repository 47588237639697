import { Component, Input, OnInit, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ParametreUtilisateurService } from '../../services/parametre-utilisateur.service';
import { AvisDto } from '../../model/model';

@Component({
  selector: 'app-avis',
  templateUrl: './avis.component.html',
  styleUrls: ['./avis.component.scss']
})
export class AvisComponent implements OnInit, OnChanges {

  @Input() refService: string;
  /** Liste des avis */
  public listeAvis: AvisDto[];

  constructor(private parametreUtilisateurService: ParametreUtilisateurService) { }

  ngOnInit() {
    // Chargement des avis
    this.parametreUtilisateurService.chargerAvis(this.refService).subscribe(
      listeAvisReponse => this.listeAvis = listeAvisReponse);
  }

  /**
   * S'execute quand la valeur de l'input change
   * Permet de raffraichir les données du composant lorsque le service est changé via la barre de recherche
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const change in changes) {
      if (changes[change] instanceof SimpleChange && change === 'refService' && changes[change].currentValue
        && !changes[change].isFirstChange()) {
        this.ngOnInit();
      }
    }
  }
}
