<h2 mat-dialog-title class="flex_dsi"><span>Vos interlocuteurs - {{nom}}</span>
  <button mat-icon-button [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
</h2>
<div mat-dialog-content>
  <mat-list>
    <mat-list-item *ngFor="let documentation of documentations">
      <div class="flex">
        <button mat-icon-button (click)="envoyerMailUnique(documentation.information)">
          <mat-icon color="primary">mail_outline</mat-icon>
        </button>
        <div>
          {{documentation.information.split('|')[0]}} <span *ngIf="documentation.libelle !== 'responsable'"></span>
        </div>
        <div *ngIf="documentation.libelle !== 'responsable'" class="italic">
          &nbsp;-&nbsp;{{documentation.libelle | titlecase}}
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="envoyerMail()" color="primary" matTooltip="Envoyer un email à tous les interlocuteurs de la liste"
    cdkFocusInitial>Envoyer un email à tous</button>
</div>