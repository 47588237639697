import { AfterViewInit, Component, ElementRef, Input, ViewChild, OnInit, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { MOIS } from '../../services/date.adapter';
import { ReferentielService } from '../../services/referentiel.service';
import { DonneesEnCours } from '../../services/donnees-en-cours';
import { ServiceGraphiqueDto, DataGraphique, NombreNotificationParMois } from '../../model/model';
import { ParametreUtilisateurService } from '../../services/parametre-utilisateur.service';
import { MeteoService } from '../../services/meteo.service';
import { GraphUtilsService } from '../../services/utilitaires/graph-utils.service';

@Component({
  selector: 'app-performances',
  templateUrl: './performances.component.html',
  styleUrls: ['./performances.component.scss']
})
export class PerformancesComponent implements OnInit, AfterViewInit, OnChanges {

  graphContextNotif: any;
  graphContextAnnuel: any;

  @Input() refService: string;

  /** Graphique du nombre de notification par mois */
  @ViewChild('myChartNotif') graphNotif: ElementRef;
  /** Graphique annuel */
  @ViewChild('myChartAnnuel') graphAnnuel: ElementRef;
  /** Url du graph1 */
  @ViewChild('iFrameGraph1') iFrameGraph1: ElementRef;
  /** Url du graph2 */
  @ViewChild('iFrameGraph2') iFrameGraph2: ElementRef;
  /** Url du graph3 */
  @ViewChild('iFrameGraph3') iFrameGraph3: ElementRef;
  /** Image du graph 1 */
  @ViewChild('fichierImgGraph1') fichierImgGraph1: ElementRef;
  /** Image du graph 2 */
  @ViewChild('fichierImgGraph2') fichierImgGraph2: ElementRef;
  /** Image du graph 3 */
  @ViewChild('fichierImgGraph3') fichierImgGraph3: ElementRef;

  serviceGraphiquesDto: ServiceGraphiqueDto;

  /** NOmbre d'abonné au notification du service */
  nbAbonnes: number;
  /** NOmbre d'abonné au notification du service */
  nbFavoris: number;

  graphEstVide: boolean;

  moisDelAnnee = MOIS;

  constructor(private referentielService: ReferentielService, private meteoService: MeteoService,
    private donneesEnCours: DonneesEnCours, private parametreUtilisateurService: ParametreUtilisateurService,
    private graphUtils: GraphUtilsService) { }

  ngOnInit() {
    this.referentielService.recupererFichierGraphique(this.refService)
      .subscribe(serviceGraphiqueReponse => {
        this.serviceGraphiquesDto = serviceGraphiqueReponse;
        if (this.serviceGraphiquesDto) {
          // -------------------------------------------
          // ----------------- Graph 1 -----------------
          // -------------------------------------------
          if (this.serviceGraphiquesDto.urlGraph1) {
            // Chargement du graph1 si l'url est renseigné
            this.iFrameGraph1.nativeElement.src = this.serviceGraphiquesDto.urlGraph1;
          } else if (this.serviceGraphiquesDto.fichierGraph1 && this.serviceGraphiquesDto.fichierGraph1.extension === 'png') {
            // Chargement de l'image si le fichier est un png
            fetch('data:image/png;base64,' + this.serviceGraphiquesDto.fichierGraph1.fichierEnBytes)
              .then(res => res.blob())
              .then(b => this.fichierImgGraph1.nativeElement.src = window.URL.createObjectURL(b));
          }

          // -------------------------------------------
          // ----------------- Graph 2 -----------------
          // -------------------------------------------
          if (this.serviceGraphiquesDto.urlGraph2) {
            // Chargement du graph2 si l'url est renseigné
            this.iFrameGraph2.nativeElement.src = this.serviceGraphiquesDto.urlGraph2;
          } else if (this.serviceGraphiquesDto.fichierGraph2 && this.serviceGraphiquesDto.fichierGraph2.extension === 'png') {
            // Chargement de l'image si le fichier est un png
            fetch('data:image/png;base64,' + this.serviceGraphiquesDto.fichierGraph2.fichierEnBytes)
              .then(res => res.blob())
              .then(b => this.fichierImgGraph2.nativeElement.src = window.URL.createObjectURL(b));
          }

          // -------------------------------------------
          // ----------------- Graph 3 -----------------
          // -------------------------------------------
          if (this.serviceGraphiquesDto.urlGraph3) {
            // Chargement du graph3 si l'url est renseigné
            this.iFrameGraph3.nativeElement.src = this.serviceGraphiquesDto.urlGraph3;
          } else if (this.serviceGraphiquesDto.fichierGraph3 && this.serviceGraphiquesDto.fichierGraph3.extension === 'png') {
            // Chargement de l'image si le fichier est un png
            fetch('data:image/png;base64,' + this.serviceGraphiquesDto.fichierGraph3.fichierEnBytes)
              .then(res => res.blob())
              .then(b => this.fichierImgGraph3.nativeElement.src = window.URL.createObjectURL(b));
          }
        }
      });

    // Souscription au notification d'abonnement
    this.donneesEnCours.refServiceChangementAbonnement.subscribe(objet => {
      // Changement temporaire pour palier au problème de synchronisation des API Veolia
      if (objet.statut != null && objet.refService === this.refService) {
        this.nbAbonnes += (objet.statut) ? 1 : -1;
      }
    });
    // Souscription au notification de favoris
    this.donneesEnCours.refServiceChangementFavoris.subscribe(refServiceReponse => this.chargerNombreFavoris(refServiceReponse));

    // Intialisation du nombre d'abonnés et de favoris
    this.chargerNombreAbonnes(this.refService);
    this.chargerNombreFavoris(this.refService);
  }

  /** dans l'afterview car pour le graph */
  ngAfterViewInit() {
    this.referentielService.chargerStats(this.refService).subscribe((nombreNotificationParMoisListe) => {
      if (nombreNotificationParMoisListe.length > 0) {
        // Parcours de la liste du nombre de notifications et ajout des informations dans les différentes listes d'alimentation du graph
        const datas: DataGraphique[] = [];

        // Génère les data pour graph circu car plus facilement manipulable
        for (const nombreNotificationParMois of nombreNotificationParMoisListe) {
          const map: Map<string, number> = new Map();
          map.set('A l\'arrêt', nombreNotificationParMois.nombreNotificationArret);
          map.set('Dégradé', nombreNotificationParMois.nombreNotificationDegrade);
          map.set('En service', nombreNotificationParMois.nombreNotificationEnService);
          const data: DataGraphique = new DataGraphique(map, this.moisDelAnnee[nombreNotificationParMois.mois.getMonth()]);
          datas.push(data);
        }
        // Gestion de l'affichage du graph - Présent s'il contient des données
        this.graphEstVide = false;
        // si pas encore de graph
        if (this.graphNotif.nativeElement) {
          this.graphNotif = this.graphUtils.creerGraphBar(
            this.graphUtils.creerDataGraph(this.graphUtils.convertirDonneesCircuToHisto(datas), true),
            'Notification / Mois',
            this.graphNotif
          );
        } else {
          this.graphNotif = this.graphUtils.resetDataGraph(this.graphNotif, this.graphUtils.convertirDonneesCircuToHisto(datas), true);
        }
      } else {
        // Gestion de l'affichage du graph - Non présent s'il ne contient pas de donnée
        this.graphEstVide = true;
      }
    });
    this.meteoService.recupererNombreIndisponibiliteParStatut(this.refService).subscribe((mapIndispo) => {
      // si pas encore de graph
      if (this.graphAnnuel.nativeElement) {
        this.graphAnnuel = this.graphUtils.creerGraphDoughnut(
          this.graphUtils.creerDataGraph([new DataGraphique(mapIndispo, 'Nombre d\'indispo par an')], true),
          'Disponibilité annuelle',
          this.graphAnnuel
        );
      } else {
        this.graphAnnuel = this.graphUtils.resetDataGraph(this.graphAnnuel,
          [new DataGraphique(mapIndispo, 'Nombre d\'indispo par an')], true);
      }
    }
    );
  }

  /**
   * S'execute quand la valeur de l'input change
   * Permet de raffraichir les données du composant lorsque le service est changé via la barre de recherche
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const change in changes) {
      if (change === 'refService' && changes[change] instanceof SimpleChange && changes[change].currentValue
        && !changes[change].isFirstChange()) {
        this.ngOnInit();
        this.ngAfterViewInit();
      }
    }
  }

  chargerNombreAbonnes(refService: string) {
    this.parametreUtilisateurService.recupererNombreAbonneService(refService)
      .subscribe((nbAbonneReponse) => this.nbAbonnes = nbAbonneReponse);
  }

  chargerNombreFavoris(refService: string) {
    this.parametreUtilisateurService.recupererNombreFavorisService(refService)
      .subscribe((nbFavorisReponse) => this.nbFavoris = nbFavorisReponse);
  }

}
