<mat-card>
  <!-- Titre de la card -->
  <mat-card-subtitle class="ion-text-center">
    <mat-icon>favorite_border</mat-icon>MES SERVICES FAVORIS
  </mat-card-subtitle>
  <!-- Content de la card -->
  <mat-card-content>

    <!-- Ligne des compteurs par statut -->
    <app-compteur-par-statut [compteurArret]="compteurArret" [compteurDegrade]="compteurDegrade"
      [compteurEnService]="compteurEnService" *ngIf="servicesFavoris"></app-compteur-par-statut>

    <!-- Tableau des favoris -->
    <ion-row>
      <ion-col>
        <mat-table [dataSource]="servicesFavoris" matSort *ngIf="servicesFavoris">
          <!-- Colonne Préférences -->
          <ng-container cdkColumnDef="prefs">
            <mat-header-cell *cdkHeaderCellDef>
              <!-- Bouton pour coher/decocher favoris dans le header -->
              <div [class]="tousFavoris?'bouton-coche':'bouton-decoche'" *ngIf="!isChargementFavoris">
                <button mat-icon-button [disabled]="isChargementFavoris || nombreServices === 0"
                  (click)="ajouterTousLesFavoris(!tousFavoris)"
                  [matTooltip]="(tousFavoris ? 'Retirer tous les services de vos favoris' : '')">
                  <mat-icon>{{tousFavoris?'favorite':'favorite_border'}}</mat-icon>
                </button>
              </div>
              <mat-spinner *ngIf="isChargementFavoris" diameter="20"></mat-spinner>
              <!-- Bouton pour cocher/decocher abonné dans le header -->
              <div [class]="tousAbonnes?'bouton-coche':'bouton-decoche'" *ngIf="!isChargementAbonnement">
                <button mat-icon-button [disabled]="isChargementAbonnement || nombreServices === 0"
                  (click)="ajouterTousLesAbonnements(!tousAbonnes)"
                  [matTooltip]="(tousAbonnes ? 'Retirer' : 'Ajouter') + ' tous les services ' + (tousAbonnes ? 'de' : 'à') + ' vos abonnement'">
                  <mat-icon>{{tousAbonnes?'email':'mail_outline'}}</mat-icon>
                </button>
              </div>
              <mat-spinner *ngIf="isChargementAbonnement" diameter="20"></mat-spinner>
            </mat-header-cell>
            <mat-cell *cdkCellDef="let service">
              <!-- Bouton favoris-->
              <div [class]="(service.refFavoris)?'bouton-coche':'bouton-decoche'"
                *ngIf="!service.modificationFavorisEnCours && !isChargementFavoris">
                <button mat-icon-button [disabled]="service.modificationFavorisEnCours || isChargementFavoris"
                  (click)="service.modificationFavorisEnCours = true;ajouterFavoris(service.reference, service.nom, !service.refFavoris)">
                  <mat-icon>{{(service.refFavoris)?'favorite':'favorite_border'}}</mat-icon>
                </button>
              </div>
              <mat-spinner *ngIf="service.modificationFavorisEnCours || isChargementFavoris" diameter="20">
              </mat-spinner>
              <!-- Bouton abonnement-->
              <div [class]="(service.abonnementActif)?'bouton-coche':'bouton-decoche'"
                *ngIf="!service.modificationAbonnementEnCours">
                <button mat-icon-button [disabled]="service.modificationAbonnementEnCours"
                  (click)="service.modificationAbonnementEnCours = true;ajouterAbonnement(service.reference, service.nom, !service.abonnementActif)">
                  <mat-icon>{{(service.abonnementActif)?'email':'mail_outline'}}</mat-icon>
                </button>
              </div>
              <mat-spinner *ngIf="service.modificationAbonnementEnCours" diameter="20"></mat-spinner>
            </mat-cell>
          </ng-container>
          <!-- Colonne Nom -->
          <ng-container cdkColumnDef="nom">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header (click)="rediriger(service)"> Nom
            </mat-header-cell>
            <mat-cell *cdkCellDef="let service" (click)="rediriger(service)"> {{service.nom | titlecase}}
            </mat-cell>
          </ng-container>
          <!-- Colonne Dernière mise à jour -->
          <ng-container cdkColumnDef="maj">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header (click)="rediriger(service)"> Enregistré le
            </mat-header-cell>
            <mat-cell *cdkCellDef="let service" (click)="rediriger(service)">
              {{service.dateMajIndisponibilite ? (service.dateMajIndisponibilite | date:'dd/LL/yyyy' | titlecase) : " -
              "}}
            </mat-cell>
          </ng-container>
          <!-- Colonne Statut -->
          <ng-container cdkColumnDef="statut">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header (click)="rediriger(service)"> Statut
            </mat-header-cell>
            <mat-cell *cdkCellDef="let service" (click)="rediriger(service)">
              <img [src]="recupererImageMeteo(service.statutIndisponibilite)" />
            </mat-cell>
          </ng-container>
          <!-- Colonne d'alerte -->
          <ng-container cdkColumnDef="alerte">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *cdkCellDef="let service">
              <button mat-icon-button matTooltip="Signaler un dysfonctionnement" (click)="alerte(service)">
                <img class="hauteur-fixe" src="assets/icon/alerte.png">
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *cdkHeaderRowDef="colonnes"></mat-header-row>
          <mat-row matRipple *cdkRowDef="let row; columns: colonnes;"></mat-row>
        </mat-table>
      </ion-col>
    </ion-row>

  </mat-card-content>
</mat-card>