<ion-content>
    <!-- Ecran mon service content -->
    <ion-grid>
        <!-- Fil d'ariane -->
        <ion-row>
            <ion-col class="fil-ariane">
                <li><a [routerLink]="['']">Accueil</a></li>
                <li><i class="material-icons md-18">keyboard_arrow_right</i></li>
                <li><a>{{serviceDto.nom | titlecase}}</a>
                </li>
            </ion-col>
        </ion-row>

        <!-- Ligne de titre -->
        <mat-card class="ligne-titre">
            <ion-row class="ion-align-items-center ligne-titre ">
                <!-- Nom du service et date du jour -->
                <ion-col class="ion-no-padding service-nom" size="auto">
                    <img [src]=recupererImageMeteo(serviceDto.statutIndisponibilite)>
                    <span><b>{{serviceDto.nom}}</b> :</span>
                </ion-col>
                <ion-col class="ion-no-padding service-nom" size="auto">
                    <span>{{date | date:'EEEE dd LLLL yyyy' | titlecase }} </span>
                </ion-col>
                <ion-col size="auto" class="ion-no-padding col-icon">
                    <!-- Interlocuteur -->
                    <app-liens [documentations]="documentations" [nom]="serviceDto.nom"></app-liens>
                    <!-- Luci -->
                    <app-luci></app-luci>
                    <!-- cartographie -->
                    <app-cartographie></app-cartographie>
                    <!-- bouton d'alerte-->
                    <button mat-icon-button matTooltip="Signaler un dysfonctionnement" (click)="alerte()">
                        <img class="hauteur-fixe" src="assets/icon/alerte.png">
                    </button>
                    <!-- Bouton favoris (le mat-icon-button permet de placer correctement les boutons) -->
                    <div [class]="(serviceDto.refFavoris)?'mat-icon-button bouton-coche':'mat-icon-button bouton-decoche'"
                        *ngIf="!(!serviceDto.reference || serviceDto.modificationFavorisEnCours)">
                        <button mat-icon-button
                            [matTooltip]="serviceDto.refFavoris ? 'Retirer le service de vos favoris' : 'Ajouter le service à vos favoris'"
                            [disabled]="serviceDto.modificationFavorisEnCours"
                            (click)="serviceDto.modificationFavorisEnCours = true;ajouterFavoris(serviceDto.reference, serviceDto.nom, !serviceDto.refFavoris)">
                            <mat-icon>{{(serviceDto.refFavoris)?'favorite':'favorite_border'}}</mat-icon>
                        </button>
                    </div>
                    <mat-spinner *ngIf="!serviceDto.reference || serviceDto.modificationFavorisEnCours" diameter="20">
                    </mat-spinner>
                    <!-- Bouton notification -->
                    <div [class]="(serviceDto.abonnementActif)?'mat-icon-button bouton-coche':'mat-icon-button bouton-decoche'"
                        *ngIf="!(!serviceDto.reference || serviceDto.modificationAbonnementEnCours)">
                        <button mat-icon-button
                            [matTooltip]="serviceDto.abonnementActif ? 'Désabonnez-vous' : 'Abonnez-vous'"
                            [disabled]="serviceDto.modificationAbonnementEnCours"
                            (click)="serviceDto.modificationAbonnementEnCours = true;ajouterAbonnement(serviceDto.reference, serviceDto.nom, !serviceDto.abonnementActif)">
                            <mat-icon>{{(serviceDto.abonnementActif)?'email':'mail_outline'}}</mat-icon>
                        </button>
                    </div>
                    <mat-spinner *ngIf="!serviceDto.reference || serviceDto.modificationAbonnementEnCours"
                        diameter="20"></mat-spinner>
                </ion-col>
            </ion-row>
        </mat-card>

        <!-- Contenu -->
        <ion-row>
            <!-- Prévisions -->
            <ion-col size-xs="12" size-md="3.5" size-xl="2.5">
                <app-previsions [refService]="refService" [serviceNom]="serviceDto.nom"></app-previsions>
            </ion-col>
            <ion-col size-xs="12" size-md="8.5" size-xl="9.5">
                <!-- Partie performances -->
                <app-performances [refService]="refService"></app-performances>
            </ion-col>
            <!-- Fiche service -->
            <ion-col size-xs="12" size-md="5" *ngIf="serviceAvecDoc">
                <app-fiche-service [serviceAvecDoc]="serviceAvecDoc"></app-fiche-service>
            </ion-col>

            <!-- Partie avis des utilisateurs -->
            <ion-col size-xs="12" size-md="7">
                <app-avis [refService]="refService"></app-avis>
            </ion-col>

        </ion-row>
    </ion-grid>
</ion-content>