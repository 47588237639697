<!-- Ligne des compteurs par statut -->
<ion-row class="ion-align-items-center">

  <!-- Compteur Arrêt -->
  <ion-col size-xs="4" size-xl="3" offset-xs="0" offset-xl="1" class="ion-text-center">
    <span class="compteur arret">{{compteurArret}}</span><img class="icone-compteur" [src]="recupererImageMeteo(statutService[0].value)" />
  </ion-col>
  <!-- Compteur Dégradé -->
  <ion-col size-xs="4" class="ion-text-center">
    <span class="compteur degrade">{{compteurDegrade}}</span><img class="icone-compteur" [src]="recupererImageMeteo(statutService[1].value)" />
  </ion-col>
  <!-- Compteur en service -->
  <ion-col size-xs="4" size-xl="3" class="ion-text-center">
    <span class="compteur en-service">{{compteurEnService}}</span><img class="icone-compteur" [src]="recupererImageMeteo(statutService[2].value)" />
  </ion-col>

</ion-row>