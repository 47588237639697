import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, NgForm, ValidationErrors } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from "rxjs/operators";
import {
  Indisponibilite,
  Service,
  ServiceDto,
  STATUT_SERVICE,
  RECUPERER_IMAGE_METEO,
  TYPE_MESSAGE,
  Utilisateur,
} from "../../model/model";
import { ReferentielService } from "../../services/referentiel.service";
import { MeteoService } from "../../services/meteo.service";
import { DialogListeIndisponibiliteComponent } from "../dialog-liste-indisponibilite/dialog-liste-indisponibilite.component";
import { DonneesEnCours } from "../../services/donnees-en-cours";
import { DialogDiffusionComponent } from "../dialog-diffusion/dialog-diffusion.component";
import { SecuriteService } from "../../services/securite.service";

@Component({
  selector: "app-contribution",
  templateUrl: "./contribution.component.html",
  styleUrls: ["./contribution.component.scss"],
})
export class ContributionComponent implements OnInit {
  /** Formulaire de la page */
  @ViewChild("contributionForm") form: NgForm;

  /** Objet métier édité dans la page */
  indispo: Indisponibilite = new Indisponibilite();

  /** Liste des services dans l'autocompletion */
  services: Observable<ServiceDto[]>;

  /** Champ d'autocompletion */
  serviceCtrl = new FormControl("", []);

  /** Liste des statuts des services*/
  statutsService = STATUT_SERVICE;

  /** fonction de transformation d'un statut en image. */
  recupererImageMeteo = RECUPERER_IMAGE_METEO;

  /** Booleen permettant de signaler qu'un enregistrement est déjà en cours */
  estEnCoursEnregistrement = false;

  utilisateur: Utilisateur;

  /** Flag de gestion de la création du contenu du mail */
  private flagBloquageChangementContenuMail = false;
  private nomPrenomUtilisateurConnecte = "";

  constructor(
    private referentielService: ReferentielService,
    private meteoService: MeteoService,
    private dialogListeIndispo: MatDialog,
    private dialogDiffusionIndispo: MatDialog,
    private donneesEnCours: DonneesEnCours,
    private securiteService: SecuriteService
  ) {}

  /**
   * Initialisation
   */
  ngOnInit() {
    // Sauvegarde du nom/prenom de l'utilisateur connecté
    this.donneesEnCours.utilisateurEnCours.subscribe((utilisateur) => {
      this.utilisateur = utilisateur;

      this.nomPrenomUtilisateurConnecte =
        utilisateur.prenom + " " + utilisateur.nom;
      if (utilisateur.numTel) {
        this.nomPrenomUtilisateurConnecte += "\n" + utilisateur.numTel;
      } else {
        this.nomPrenomUtilisateurConnecte += "\n" + utilisateur.email;
      }
      // Récupére tous les services pour afficher une erreur si l'utilisateur tape un service ne faisant pas parti de la liste
    });
    // Autocomplétion lors du changement de valeur de l'input
    this.serviceCtrl.valueChanges
      .pipe(
        startWith(""),
        // Attend 500ms avant d'executer la requete
        debounceTime(500),
        // Envoi une requete sur le mot a changé
        distinctUntilChanged(),
        // Ne fait pas de nouvelle requete si vide
        map((service) => this.filtrer(service))
      )
      .subscribe((s) => {
        this.services = s;
      });
    this.referentielService
      .recuperServicesParNomEtTrigramme("")
      .subscribe((services) => {
        this.serviceCtrl.setValidators(() =>
          this.creerValidateurDeServices(
            this.serviceCtrl,
            services.map((value: ServiceDto) => value.nom)
          )
        );
      });
  }

  /**
   * Fonction pour filtrer la liste des services
   * @param filtre valeur saisie dans le champ de sélection du service
   */
  private filtrer(filtre: string): Observable<ServiceDto[]> {
    if (filtre && filtre.trim().length > 1) {
      return this.referentielService.recuperServicesParNomEtTrigramme(
        filtre.trim()
      );
    } else {
      return new Observable<ServiceDto[]>();
    }
  }

  /**
   * Ajoute dans l'indispo le service sélectionné
   * @param service Service sélectionné
   */
  selectionerService(service: Service) {
    this.indispo.service = service;
    this.changerContenuMail();
  }

  /** changement du flag */
  bloquerChangementContenuMail(event: KeyboardEvent) {
    if (event && this.indispo.commentaire !== this.genererMail()) {
      this.flagBloquageChangementContenuMail = true;
    } else {
      this.flagBloquageChangementContenuMail = false;
    }
  }

  /** Création du contenu du mail automatique tant que non débrayé */
  changerContenuMail(): void {
    if (!this.flagBloquageChangementContenuMail && this.indispo) {
      this.indispo.commentaire = this.genererMail();
    }
  }

  private genererMail(): string {
    let commentaire = "";
    if (this.indispo.service) {
      commentaire =
        commentaire +
        "Bonjour,\nNous vous informons que le service " +
        this.indispo.service.nom;
    }
    if (this.indispo.statut) {
      if (this.indispo.statut === this.statutsService[0].value) {
        commentaire += " sera à l'arrêt";
      } else if (this.indispo.statut === this.statutsService[1].value) {
        commentaire += " fonctionnera en mode dégradé";
      } else {
        commentaire += " sera remis en service";
      }
    }
    if (
      this.indispo.dateDebut &&
      this.indispo.dateFin &&
      this.indispo.dateDebut.getTime() === this.indispo.dateFin.getTime()
    ) {
      commentaire =
        commentaire + " le " + this.formaterDate(this.indispo.dateDebut) + ".";
    } else {
      if (this.indispo.dateDebut) {
        commentaire =
          commentaire + " du " + this.formaterDate(this.indispo.dateDebut);
      }
      if (this.indispo.dateFin) {
        commentaire =
          commentaire + " au " + this.formaterDate(this.indispo.dateFin) + ".";
      }
    }
    if (this.indispo.statut) {
      if (this.indispo.statut === this.statutsService[2].value) {
        commentaire =
          commentaire + "\nNous vous remercions de votre compréhension.";
      } else {
        commentaire =
          commentaire +
          "\nNous vous prions de bien vouloir nous excuser pour la gêne occasionnée.";
      }
    }
    if (true) {
      commentaire += "\n" + this.nomPrenomUtilisateurConnecte;
    }
    return commentaire;
  }

  /** Format une Date en chaine de caractères */
  formaterDate(laDate: Date): string {
    if (laDate) {
      return (
        this.formatNombre(laDate.getDate()) +
        "/" +
        this.formatNombre(laDate.getMonth() + 1) +
        "/" +
        laDate.getFullYear()
      );
    } else {
      return "";
    }
  }

  /** Retourne un nombre sur 2 caractères */
  private formatNombre(n: number): string {
    if (n < 10) {
      return "0" + n;
    } else {
      return "" + n;
    }
  }

  /**
   * Enregistre et diffuse l'indispo
   */
  enregistrerEtDiffuser() {
    // Si un enregistrement est déjà en cours on ne va pas plus loin, sinon passe le booleen à true
    if (this.estEnCoursEnregistrement) {
      return;
    } else {
      this.estEnCoursEnregistrement = true;
    }

    // Ouverture de la dialog de diffusion
    const dialogRef = this.dialogDiffusionIndispo.open(
      DialogDiffusionComponent,
      {
        data: { indispo: this.indispo, appelDepuisHistorique: false },
      }
    );
    dialogRef.afterClosed().subscribe((aDiffuser: boolean) => {
      if (aDiffuser) {
        this.executerEnregistrementEtOuDiffusion(true);
      } else if (aDiffuser === false) {
        this.executerEnregistrementEtOuDiffusion(false);
      } else {
        this.estEnCoursEnregistrement = false;
      }
    });
  }

  /**
   * Enregistre l'indispo uniquement
   */
  enregistrer() {
    // Si un enregistrement est déjà en cours on ne va pas plus loin, sinon passe le booleen à true
    if (this.estEnCoursEnregistrement) {
      return;
    } else {
      this.estEnCoursEnregistrement = true;
    }

    this.executerEnregistrementEtOuDiffusion(false);
  }

  /**
   * Enregistre et/ou diffuse l'indispo
   * @param aDiffuser true pour diffuser l'indispo en plus de l'enregistrement, false sinon
   */
  private executerEnregistrementEtOuDiffusion(aDiffuser: boolean) {
    this.indispo.aDiffuser = aDiffuser;
    this.meteoService.creerIndisponibilite(this.indispo).subscribe(
      (indispoDto) => {
        // Pour notifier les autres composants la création d'une nouvelle indispo
        this.donneesEnCours.changerNouvelleIndispo(indispoDto);
        // Réinitialisation du formulaire
        this.viderFormulaire();
        // Information pour l'utilisateur
        this.donneesEnCours.ajouterMessageSnackBar(
          "Enregistrement" +
            (aDiffuser ? " et diffusion" : "") +
            " effectué avec succès.",
          TYPE_MESSAGE.succes
        );
      },
      () => {}, // Error du subscribe déjà géré dans le service
      () => (this.estEnCoursEnregistrement = false)
    ); // Finally du subscribe
  }

  /**
   * Duplique l'indisponibilité passé en paramètre
   * @param indispoSource indisponibilité contenant les informations à copier
   */
  private dupliquerIndisponibilite(indispoSource: Indisponibilite) {
    this.indispo = new Indisponibilite();
    // Copie du service
    this.serviceCtrl.setValue(indispoSource.service.nom);
    this.indispo.service = indispoSource.service;
    // Copie du commentaire
    this.indispo.commentaire = indispoSource.commentaire;
    // Copie du statut
    this.indispo.statut = indispoSource.statut;
    // Copie date de début
    this.indispo.dateDebut = indispoSource.dateDebut;
    // Copie date de fin
    this.indispo.dateFin = indispoSource.dateFin;
  }

  /**
   * Ouverture de la fenêtre de dialog et souscription à son retour
   */
  ouvrirDialogHistorique() {
    // Vérification qu'un service est sélectionné pour accèder à l'historique
    if (!this.indispo.service) {
      this.donneesEnCours.ajouterMessageSnackBar(
        "Sélectionnez un service pour accèder à l'historique !",
        TYPE_MESSAGE.avertissement
      );
      return;
    }
    this.meteoService
      .rechercherHistorique(this.indispo.service.reference)
      .subscribe((indispos) => {
        // Ouvertire de la dialog
        const dialogRef = this.dialogListeIndispo.open(
          DialogListeIndisponibiliteComponent,
          {
            height: "80%",
            width: "80%",
            data: { liste: indispos, nom: this.indispo.service.nom },
          }
        );
        // Souscription au retour de la dialog
        dialogRef.afterClosed().subscribe((indispoPopUp) => {
          if (indispoPopUp) {
            if (indispoPopUp.aDiffuser) {
              this.ouvrirDialogDiffusion(indispoPopUp);
            } else {
              this.dupliquerIndisponibilite(indispoPopUp.indispo);
            }
          }
        });
      });
  }

  /**
   * Gestion de l'ouverture et des retours de la dialog de diffusion appelé depuis l'historique
   * @param indispoPopUp Objet retourné par la dialogue historique à sa fermeture composé des attributs suivant
   * {indispo: Indisponibilite, aDiffuser: boolean}
   */
  private ouvrirDialogDiffusion(indispoPopUp: any) {
    const dialogRefDiff = this.dialogDiffusionIndispo.open(
      DialogDiffusionComponent,
      {
        data: { indispo: indispoPopUp.indispo, appelDepuisHistorique: true },
      }
    );
    dialogRefDiff.afterClosed().subscribe((aDiffuser: boolean) => {
      if (aDiffuser) {
        this.meteoService
          .diffuserIndisponibilite(
            indispoPopUp.indispo.reference,
            indispoPopUp.indispo.service.nom
          )
          .subscribe(() => {
            this.viderFormulaire();
            this.donneesEnCours.ajouterMessageSnackBar(
              "Diffusion effectuée avec succès.",
              TYPE_MESSAGE.succes
            );
          });
      } else {
        this.ouvrirDialogHistorique();
      }
    });
  }

  /**
   * Vide la formulaire et reset les validations sur les champs
   */
  viderFormulaire() {
    this.indispo = new Indisponibilite();
    this.form.resetForm();
    this.serviceCtrl.reset();
    this.flagBloquageChangementContenuMail = false;
  }

  private creerValidateurDeServices(
    control: FormControl,
    services: string[]
  ): ValidationErrors {
    if (!control.value || control.value.trim().length < 1) {
      return null;
    }
    const service: string = control.value.trim();
    // Vérifie si ce qui est taper correspond à un service
    if (
      services.findIndex((value: string) =>
        value.toUpperCase().includes(service.toUpperCase())
      ) > -1
    ) {
      return null;
    } else {
      return {};
    }
  }
}
