<div mat-dialog-title>
  <div class="spinner">
    <mat-spinner *ngIf="!(donnees.length > 1)" diameter="20"></mat-spinner>
  </div>
  <div class="titre"><span>Répartition des abonnements et favoris en fonction des directions</span></div>
  <button mat-icon-button [mat-dialog-close] class="close">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div class="graphique">
    <div class="taille-graphique">
      <canvas #myChart width="1" height="1"></canvas>
    </div>
    <div class="legende">
      <div>
        <span>Diagramme sectoriel&nbsp;&nbsp;</span>
        <mat-slide-toggle [(ngModel)]="isHistogramme" (click)="switchGraph()" color="primary" [matTooltip]="'Afficher le graphique sous forme ' + (!isHistogramme ? 'd\'histogramme' : 'de diagramme sectoriel')">
          Histogramme
        </mat-slide-toggle>
      </div>
      <mat-table [dataSource]="legendes" *ngIf="legendes.length > 0">
        <ng-container cdkColumnDef="favoris">
          <mat-header-cell *cdkHeaderCellDef> {{titre1}} </mat-header-cell>
          <mat-cell *cdkCellDef="let legende">
            <div [style.backgroundColor]="legende.couleur1" class="legende-etiquette"> {{legende.nbr1}} </div>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef> {{nombre1}} </mat-footer-cell>
        </ng-container>
        <ng-container cdkColumnDef="direction">
          <mat-header-cell *cdkHeaderCellDef> Directions </mat-header-cell>
          <mat-cell *cdkCellDef="let legende"> {{legende.direction | titlecase}} </mat-cell>
          <mat-footer-cell *matFooterCellDef> Total </mat-footer-cell>
        </ng-container>
        <ng-container cdkColumnDef="abonnement">
          <mat-header-cell *cdkHeaderCellDef> {{titre2}} </mat-header-cell>
          <mat-cell *cdkCellDef="let legende">
            <div [style.backgroundColor]="legende.couleur2" class="legende-etiquette"> {{legende.nbr2}} </div>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef> {{nombre2}} </mat-footer-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="colonnes"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: colonnes;"></mat-row>
        <mat-footer-row *matFooterRowDef="colonnes"></mat-footer-row>
      </mat-table>
    </div>
  </div>
</div>