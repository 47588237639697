import { Component, OnInit } from '@angular/core';
import { RECUPERER_IMAGE_METEO, STATUT_SERVICE, ServiceAvecStatutDto, Service } from '../../model/model';
import { ReferentielService } from '../../services/referentiel.service';
import { DonneesEnCours } from '../../services/donnees-en-cours';
import { Router } from '@angular/router';
import { DialogAlertComponent } from '../../commun/dialog-alert/dialog-alert.component';
import { MatDialog } from '@angular/material/dialog';

/**
 * service par direction et service favoris sont deux composants très similaire mais avec
 * quelques différences notables
 */
@Component({
  selector: 'app-mes-services-favoris',
  templateUrl: './mes-services-favoris.component.html',
  styleUrls: ['./mes-services-favoris.component.scss', '../../commun/style/style-liste-service.scss']
})
export class MesServicesFavorisComponent implements OnInit {

  /** Fonction de récupération des icones des statut */
  recupererImageMeteo = RECUPERER_IMAGE_METEO;

  /** Liste des statuts */
  statutService = STATUT_SERVICE;

  /** Compteur des différents statut */
  compteurArret: number;
  compteurDegrade: number;
  compteurEnService: number;

  /** Gestion du nombre d'abonnnés pour le bouton tout cocher */
  private nombreAbonnes = -1;
  nombreServices = 0;
  isChargementFavoris = false;
  isChargementAbonnement = false;

  /** Liste des services favoris de l'utilisateur */
  servicesFavoris: ServiceAvecStatutDto[];

  /** Liste des colonnes affichées */
  colonnes: string[] = ['prefs', 'nom', 'maj', 'statut', 'alerte'];

  dateSelectionnee: Date = new Date();

  constructor(private router: Router, private donneesEnCours: DonneesEnCours, private referentielService: ReferentielService,
    private dialogAlertComponent: MatDialog) { }

  ngOnInit() {
    // Récupération de la liste des favoris
    this.chargerListeServiceFavoris();

    // Modif liste quand changement date
    this.donneesEnCours.dateCalendrierSelectionnee.subscribe(date => {
      this.dateSelectionnee = date;
      this.chargerListeServiceFavoris();
    });

    // Modif liste quand changement abonnement
    this.donneesEnCours.refServiceChangementAbonnement.subscribe(objet => {
      // On vérifie que la requete n'a pas échoué
      if (objet.statut != null) {
        // On ne recharge pas la liste à cause du délai de modification des abonnements dans l'API Google Groups
        const serviceTrouve = this.servicesFavoris.find(service => service.reference === objet.refService);
        if (serviceTrouve) {
          serviceTrouve.abonnementActif = objet.statut;
          serviceTrouve.modificationAbonnementEnCours = false;
        }
      } else {
        const serviceTrouve = this.servicesFavoris.find(service => service.reference === objet.refService);
        if (serviceTrouve) {
          serviceTrouve.modificationAbonnementEnCours = false;
        }
      }
      if (!this.servicesFavoris.find(service => service.modificationAbonnementEnCours === true)) {
        this.isChargementAbonnement = false;
      }
    });

    // Modif liste quand changement direction
    this.donneesEnCours.nouvelleIndispo.subscribe((indispo) => {
      if (indispo.refFavoris) {
        this.chargerListeServiceFavoris();
      }
    });

    // Modif liste quand changement favoris
    this.donneesEnCours.refServiceChangementFavoris.subscribe(refService => this.chargerListeServiceFavoris());

  }

  get tousFavoris(): boolean {
    return this.nombreServices > 0 ? true : false;
  }

  get tousAbonnes(): boolean {
    return this.nombreAbonnes === this.nombreServices && this.nombreServices !== 0 ? true : false;
  }

  /** Chargement de la liste des services favoris */
  chargerListeServiceFavoris() {
    this.referentielService.recupererServicesFavorisParDate(this.dateSelectionnee).subscribe(serviceFavorisReponse => {
      this.servicesFavoris = serviceFavorisReponse;
      this.compteurArret = serviceFavorisReponse.filter(service => service.statutIndisponibilite === STATUT_SERVICE[0].value).length;
      this.compteurDegrade = serviceFavorisReponse.filter(service => service.statutIndisponibilite === STATUT_SERVICE[1].value).length;
      this.compteurEnService = serviceFavorisReponse.filter(service => service.statutIndisponibilite === STATUT_SERVICE[2].value
        || !service.statutIndisponibilite).length;
      this.nombreAbonnes = serviceFavorisReponse.filter(service => service.abonnementActif).length;
      this.nombreServices = serviceFavorisReponse.length;
      this.isChargementFavoris = false;
    });
  }

  /** Fonction au clic sur un bouton favoris */
  ajouterFavoris(refService: string, nomService: string, statut: boolean) {
    let fav = 'Favoris';
    if (!statut) {
      fav = 'Défavoris';
    }
    this.donneesEnCours.changerFavoris(refService, statut);
  }

  /** fonction au clic sur un bouton abonnement */
  ajouterAbonnement(refService: string, nomService: string, statut: boolean) {
    let abo = 'Abonnement';
    if (!statut) {
      abo = 'Désabonnement';
    }
    this.donneesEnCours.changerAbonnement(refService, statut);
  }

  rediriger(service: ServiceAvecStatutDto) {
    // On passe null pour réinitialiser la direction et pas charger la carto tout de suite dans l'autre écran
    this.donneesEnCours.changerDirection(null);
    this.donneesEnCours.changerDirection(service.refDirection);
    this.router.navigate(['/mon-service/' + service.reference]);
  }

  /**
   * @param statut
   *
   * Fonction qui permet d'ajouter/retirer tous les services aux favoris
   */
  ajouterTousLesFavoris(statut: boolean) {
    this.isChargementFavoris = true;
    this.servicesFavoris.forEach(s => {
      if (statut && !s.refFavoris) {
        this.ajouterFavoris(s.reference, s.nom, true);
      } else if (!statut && s.refFavoris) {
        this.ajouterFavoris(s.reference, s.nom, false);
      }
    });
  }

  /**
   * @param statut
   *
   * Fonction qui permet d'ajouter/retirer tous les services aux abonnements
   */
  ajouterTousLesAbonnements(statut: boolean) {
    this.servicesFavoris.forEach(s => {
      this.isChargementAbonnement = true;
      if (statut && !s.abonnementActif) {
        s.modificationAbonnementEnCours = true;
        this.ajouterAbonnement(s.reference, s.nom, true);
      } else if (!statut && s.abonnementActif) {
        s.modificationAbonnementEnCours = true;
        this.ajouterAbonnement(s.reference, s.nom, false);
      }
    });
  }

  alerte(service: ServiceAvecStatutDto): void {
    const s: Service = new Service();
    s.reference = service.reference;
    this.dialogAlertComponent.open(DialogAlertComponent, {
      data: s,
      maxWidth: '45%'
    });
  }
}
