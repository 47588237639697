import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Utilisateur, ServiceDto } from '../model/model';
import { DonneesEnCours } from '../services/donnees-en-cours';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ReferentielService } from '../services/referentiel.service';
import { SnackBarMessageComponent } from '../commun/snack-bar-message/snack-bar-message.component';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { InformationVersionService } from '../services/information-version.service';

@Component({
  selector: 'app-entete',
  templateUrl: './entete.component.html',
  styleUrls: ['./entete.component.scss'],
  // Anim de l'input
  animations: [
    trigger('slideInOut', [
      // Taille de l'input à la fin de l'anim
      state('true', style({ width: '350px' })),
      state('false', style({ width: '0' })),
      // Duree de l'anim au à l'allez et au retour
      transition('true => false', animate('300ms ease-in')),
      transition('false => true', animate('300ms ease-out'))
    ])
  ]
})

export class EnteteComponent implements OnInit {

  get dynamicStyles() {
    return {
      'flex': this.showLogo ? '0 0 auto' : '0 0 0',
      'width': this.showLogo ? 'auto' : '0',
    };
  };  

  services: Observable<ServiceDto[]>;
  utilisateur: Utilisateur;
  showLogo: boolean = true; 

  /** num commit à afficher sur l'icone de l'appli */
  numCommit: string;

  serviceCtrl = new FormControl('', [
  ]);

  // Pour l'input
  searchVisible = false;
  @ViewChild('input') inputElement: ElementRef;

  constructor(private router: Router, private donneesEnCours: DonneesEnCours, private referentielService: ReferentielService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    // Autocomplétion lors du changement de valeur de l'input
    this.serviceCtrl.valueChanges.pipe(
      startWith(''),
      // Attend 500ms avant d'executer la requete
      debounceTime(500),
      // Envoi une requete sur le mot a changé
      distinctUntilChanged(),
      // Ne fait pas de nouvelle requete si vide
      map((service) => this.filtrer(service))
    ).subscribe((s) => {
      this.services = s;
    });

    
    // Récupération des infos utilisateur
    this.donneesEnCours.utilisateurEnCours.subscribe((utilisateur) => {
      this.utilisateur = utilisateur;
      // Récupére tous les services pour afficher une erreur si l'utilisateur tape un service ne faisant pas parti de la liste
      this.referentielService.recuperServicesParNomEtTrigramme('')
        .subscribe((services) => {
          this.serviceCtrl.setValidators(() =>
            this.creerValidateurDeServices(this.serviceCtrl, services.map((value: ServiceDto) => value.nom)));
        });
    });

    this.donneesEnCours.erreurApplication.subscribe((e) => {
      this.snackBar.openFromComponent(SnackBarMessageComponent, { data: e, verticalPosition: 'top' });
    });

    /** récupération du num de commit de la versio, */
    // this.informationVersion.recupererInformationApplication().subscribe(version => {
    //   this.numCommit = version['git.commit.id'];
    // });

  }

  // Fonction pour filtrer la liste des services
  private filtrer(filtre: string): Observable<ServiceDto[]> {
    if (filtre && filtre.trim().length > 1) {
      return this.referentielService.recuperServicesParNomEtTrigramme(filtre.trim());
    } else {
      return new Observable<ServiceDto[]>();
    }
  }

  private creerValidateurDeServices(control: FormControl, services: string[]): ValidationErrors {
    if (!control.value || control.value.trim().length < 1) {
      return null;
    }
    const service: string = control.value.trim();
    // Vérifie si ce qui est taper correspond à un service
    if (services.findIndex((value: string) => value.toUpperCase().includes(service.toUpperCase())) > -1) {
      return null;
    } else {
      return {};
    }
  }

  public rediriger(serviceDto: ServiceDto) {
    this.fermerAnimation();
    // On passe null pour réinitialiser la direction et pas charger la carto tout de suite dans l'autre écran
    this.donneesEnCours.changerDirection(null);
    this.donneesEnCours.changerDirection(serviceDto.refDirection);
    this.router.navigate(['/mon-service/' + serviceDto.reference]);
  }

  // Pour l'animation de l'input
  public fermerAnimation(): void {
    this.searchVisible = false;
    this.serviceCtrl.setValue('');
    this.serviceCtrl.reset();
    this.inputElement.nativeElement.value = '';
  }

  public ouvrirAnimation(): void {
    this.searchVisible = true;
    this.inputElement.nativeElement.focus();
  }

  public retourAccueil(): void {
    this.fermerAnimation();
    this.router.navigate(['/']);
  }

}
