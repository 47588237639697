import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-cartographie",
  templateUrl: "./dialog-cartographie.component.html",
  styleUrls: ["./dialog-cartographie.component.scss"],
})
export class DialogCartographieComponent implements OnInit {
  /** le tag HTML */
  @ViewChild("imgCartographie", { static: true }) image!: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<DialogCartographieComponent>,
    @Inject(MAT_DIALOG_DATA) private fluxImage: Blob
  ) {}

  ngOnInit() {
    this.image.nativeElement.src = window.URL.createObjectURL(this.fluxImage);
  }

  fermer() {
    this.dialogRef.close();
  }
}
