import { Component, OnInit } from '@angular/core';
import { ServiceAvecStatutDto, Supervision, RECUPERER_IMAGE_METEO_SUPERVISION, STATUT_SUPERVISION } from '../../model/model';
import { ReferentielService } from '../../services/referentiel.service';
import { DonneesEnCours } from '../../services/donnees-en-cours';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

/**
 * service par direction et service favoris sont deux composants très similaire mais avec
 * quelques différences notables
 */
@Component({
  selector: 'app-supervision-journaliere-dsi',
  templateUrl: './supervision-journaliere-dsi.component.html',
  styleUrls: ['./supervision-journaliere-dsi.component.scss', '../../commun/style/style-liste-service.scss']
})
export class SupervisionJournaliereDsiComponent implements OnInit {

  /** Fonction de récupération des icones des statut */
  recupererImageMeteoSupervision = RECUPERER_IMAGE_METEO_SUPERVISION;

  /** Liste des services de la direction */
  supervision: Supervision[];

  /** Liste des statuts */
  statutService = STATUT_SUPERVISION;

  /** Compteur des différents statut */
  compteurArret: number;
  compteurDegrade: number;
  compteurEnService: number;

  /** Gestion du nombre d'abonnnés pour le bouton tout cocher */
  private nombreAbonnes = -1;
  nombreServices = 0;
  isChargementFavoris = false;
  isChargementAbonnement = false;

  /** Liste des services favoris de l'utilisateur */
  servicesFavoris: ServiceAvecStatutDto[];

  /** Liste des colonnes affichées */
  colonnes: string[] = ['prefs', 'nom', 'maj', 'statut'];

  dateSelectionnee: Date = new Date();

  constructor(private router: Router, private donneesEnCours: DonneesEnCours, private referentielService: ReferentielService,
    private dialogAlertComponent: MatDialog) { }

  ngOnInit() {
    // Récupération des donnees du GSheet
    this.chargerListeServiceSupervision();


  }

  /** Chargement de la liste des services favoris */
  chargerListeServiceSupervision() {
    this.referentielService.chargerDonneesSupervision().subscribe(SupervisionReponse => {
      this.supervision = SupervisionReponse;
        this.compteurArret = SupervisionReponse.filter(supervision => supervision.etat === 'KO majeur').length;
        this.compteurDegrade = SupervisionReponse.filter(supervision => supervision.etat === 'KO mineur').length;
        this.compteurEnService = SupervisionReponse.filter(supervision => supervision.etat === 'OK').length;
    });
  }

  public envoyerMail(destinataire: string): void {
    window.open('https://mail.google.com/mail/?tf=cm&to=' + destinataire);
  }

  public verifierSiDateJour(dateSupervision): boolean {
    const date1 = new Date(dateSupervision);
    const date1bis = date1.setHours(0,0,0,0);
    const date2bis = this.dateSelectionnee.setHours(0,0,0,0);

    if(date1bis === date2bis){
      return true;
    }else{
      return false;
    }
  }
}
