<mat-card class="conteneur-calendrier">
  <mat-card-subtitle class="ion-text-center">
    <mat-icon>cloud_queue</mat-icon> PREVISIONS DE MES SERVICES FAVORIS
  </mat-card-subtitle>
  <mat-card-content class="calendrier">
    <mat-calendar></mat-calendar>
  </mat-card-content>
  <mat-card-footer class="centrer">
    <div>
      <div class="disque" [style.background-color]="vert"></div>
      <span>En service</span>
    </div>
    <div>
      <div class="disque" [style.background-color]="orange"></div>
      <span>Service dégradé</span>
    </div>
    <div>
      <div class="disque" [style.background-color]="rouge"></div>
      <span>Service à l'arrêt</span>
    </div>
  </mat-card-footer>
</mat-card>
