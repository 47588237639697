import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { Direction, RECUPERER_IMAGE_METEO, ServiceAvecStatutDto, STATUT_SERVICE } from '../../model/model';
import { DonneesEnCours } from '../../services/donnees-en-cours';
import { ReferentielService } from '../../services/referentiel.service';

/**
 * service par direction et service favoris sont deux composants très similaire mais avec
 * quelques différences notables
 */
@Component({
  selector: 'app-services-par-direction',
  templateUrl: './services-par-direction.component.html',
  styleUrls: ['./services-par-direction.component.scss', '../../commun/style/style-liste-service.scss']
})
export class ServicesParDirectionComponent implements OnInit {

  /** Direction sélectionnée */
  direction: Direction;
  /** Liste des directions */
  directions: Direction[];
  /** Liste des services de la direction */
  services: ServiceAvecStatutDto[];
  /** Fonction de récupération des liens des icones des statuts */
  recupererImageMeteo = RECUPERER_IMAGE_METEO;
  /** Liste des colonnes affichées dans le tableau */
  colonnes: string[] = ['prefs', 'nom', 'maj', 'statut'];
  /** Date sélectionné dans le calendrier */
  dateSelectionnee: Date = new Date();
  /** Compteur des différents statut */
  compteurArret: number;
  compteurDegrade: number;
  compteurEnService: number;

  /** Gestion du nombre d'abonnnés et de favoris pour les boutons tout cocher */
  private nombreAbonnes = -1;
  private nombreFavoris = -1;
  private nombreServices = 0;
  isChargementFavoris = false;
  isChargementAbonnement = false;

  isChargementDirection = false;

  @ViewChild(MatSelect) matSelect: MatSelect;

  constructor(private donneesEnCours: DonneesEnCours, private referentielService: ReferentielService, private router: Router) { }

  ngOnInit() {
    this.init();

    // Modif liste quand changement direction
    this.donneesEnCours.nouvelleIndispo.subscribe((indispo) => {
      if (indispo.refDirection === this.direction.reference) {
        this.chargerListeService();
      }
    });

    // Modif liste quand changement date
    this.donneesEnCours.dateCalendrierSelectionnee.subscribe(date => {
      this.dateSelectionnee = date;
      this.chargerListeService();
    });

    // Modif liste quand changement abonnement
    this.donneesEnCours.refServiceChangementAbonnement.subscribe(objet => {
      // On vérifie que la requete n'a pas échoué
      if (objet.statut != null) {
        // On ne recharge pas la liste à cause du délai de modification des abonnements dans l'API Google Groups
        const serviceTrouve = this.services.find(service => service.reference === objet.refService);
        if (serviceTrouve) {
          serviceTrouve.abonnementActif = objet.statut;
          serviceTrouve.modificationAbonnementEnCours = false;
          this.nombreAbonnes++;
        }
      } else {
        const serviceTrouve = this.services.find(service => service.reference === objet.refService);
        if (serviceTrouve) {
          serviceTrouve.modificationAbonnementEnCours = false;
        }
      }
      if (!this.services.find(service => service.modificationAbonnementEnCours === true)) {
        this.isChargementAbonnement = false;
      }
    });

    // Modif liste quand changement favoris
    this.donneesEnCours.refServiceChangementFavoris.subscribe(refService => this.chargerListeService());
  }

  /**
   * Met à jour la liste des services
   */
  private chargerListeService() {
    if (this.direction) {
      this.referentielService.chargerServicesParDirection(this.direction.reference, this.dateSelectionnee).subscribe((servicesReponse) => {
        this.services = servicesReponse;
        this.compteurArret = servicesReponse.filter(service => service.statutIndisponibilite === STATUT_SERVICE[0].value).length;
        this.compteurDegrade = servicesReponse.filter(service => service.statutIndisponibilite === STATUT_SERVICE[1].value).length;
        this.compteurEnService = servicesReponse.filter(service => service.statutIndisponibilite === STATUT_SERVICE[2].value
          || !service.statutIndisponibilite).length;
        this.nombreAbonnes = servicesReponse.filter(service => service.abonnementActif).length;
        this.nombreFavoris = servicesReponse.filter(service => service.refFavoris).length;
        this.nombreServices = servicesReponse.length;
        this.isChargementFavoris = false;
        this.isChargementDirection = false;
      });
    }
  }

  /** Permet de savoir s'il faut tout mettre en favoris ou tout retirer */
  get tousFavoris(): boolean {
    return this.nombreFavoris === this.nombreServices;
  }

  /** Permet de savoir s'il faut tout mettre en abonnement ou tout retirer */
  get tousAbonnes(): boolean {
    return this.nombreAbonnes === this.nombreServices;
  }

  selectionnerDirection(event) {
    this.isChargementDirection = true;
    this.donneesEnCours.changerDirection(event.value);
  }

  /** Permet de naviguer sur la page mon service */
  rediriger(service: ServiceAvecStatutDto) {
    // On passe null pour réinitialiser la direction et pas charger la carto tout de suite dans l'autre écran
    this.donneesEnCours.changerDirection(null);
    this.donneesEnCours.changerDirection(service.refDirection);
    this.router.navigate(['/mon-service/' + service.reference]);
  }

  /** ajoute/supprime le service aux favoris */
  ajouterFavoris(refService: string, nomService: string, statut: boolean) {
    let fav = 'Favoris';
    if (!statut) {
      fav = 'Défavoris';
    }
    this.donneesEnCours.changerFavoris(refService, statut);
  }

  /** ajoute/supprime le service aux abonnement */
  ajouterAbonnement(refService: string, nomService: string, statut: boolean) {
    let abo = 'Abonnement';
    if (!statut) {
      abo = 'Désabonnement';
    }
    this.donneesEnCours.changerAbonnement(refService, statut);
  }

  /**
   * @param statut
   *
   * Fonction qui permet d'ajouter/retirer tous les services aux favoris
   */
  ajouterTousLesFavoris(statut: boolean) {
    this.isChargementFavoris = true;
    this.services.forEach(s => {
      if (statut && !s.refFavoris) {
        this.ajouterFavoris(s.reference, s.nom, true);
      } else if (!statut && s.refFavoris) {
        this.ajouterFavoris(s.reference, s.nom, false);
      }
    });
  }

  /**
   * @param statut
   *
   * Fonction qui permet d'ajouter/retirer tous les services aux abonnement
   */
  ajouterTousLesAbonnements(statut: boolean) {
    this.services.forEach(s => {
      this.isChargementAbonnement = true;
      if (statut && !s.abonnementActif) {
        s.modificationAbonnementEnCours = true;
        this.ajouterAbonnement(s.reference, s.nom, true);
      } else if (!statut && s.abonnementActif) {
        s.modificationAbonnementEnCours = true;
        this.ajouterAbonnement(s.reference, s.nom, false);
      }
    });
  }

  private init() {
    this.isChargementDirection = true;
    this.referentielService.chargerToutesLesDirection().subscribe((dir) => {
      this.directions = dir;
      this.donneesEnCours.directionEnCours.subscribe((d) => {
        this.direction = d;
        this.chargerListeService();
      });
    });
  }
}
