import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Direction, IndisponibiliteDto, Utilisateur, TYPE_MESSAGE } from '../model/model';
import { ReferentielService } from './referentiel.service';
import { SecuriteService } from './securite.service';
import { filter, debounceTime } from 'rxjs/operators';
import { ParametreUtilisateurService } from './parametre-utilisateur.service';
import { AuthentificationService } from './authentification/authentification.service';

@Injectable({
  providedIn: 'root'
})
export class DonneesEnCours {

  constructor(private referentielService: ReferentielService, private securiteService: SecuriteService,
    private parametreUtilisateurService: ParametreUtilisateurService, private authentificationService: AuthentificationService) { }

  /** Permet de notifier lors d'un changement d'utilisateur */
  private utilisateur = new BehaviorSubject<Utilisateur>(null);

  /** Permet de notifier lors d'un changement de direction */
  private direction = new BehaviorSubject<Direction>(null);

  /** Permet de notifier lors d'un changement d'indispo */
  private indisponibilite = new BehaviorSubject<IndisponibiliteDto>(null);

  private dateCalendrier = new BehaviorSubject<Date>(null);

  /** Permet de notifier d'un changement d'abonnement */
  private refServiceAbonnement = new BehaviorSubject<{ refService: string, statut: boolean }>(null);

  /** Permet de notifier d'un changement de favoris */
  private refServiceFavoris = new BehaviorSubject<string>(null);

  /** Gestion des erreurs */
  private erreurs = new BehaviorSubject<{ message: string, codeErreur: number, typeMessage: TYPE_MESSAGE }>(null);

  /**
   * Fait la requete http et ajoute l'direction à la file
   */
  chargerUtilisateur() {
    this.securiteService.connexionUtilisateur().subscribe((u) => {
      this.authentificationService.getUtilisateur().subscribe(ug => {
        u.nom = ug.nom;
        u.prenom = ug.prenom;
        u.image = ug.image;
        this.utilisateur.next(u);
      });
      this.direction.next(u.direction);
    });
  }

  /**
   * Fait la requete http et ajoute l'direction à la file
   */
  changerDirection(refDirection: string) {
    if (refDirection) {
      this.referentielService.chargerDirection(refDirection).subscribe((d) => this.direction.next(d));
    } else {
      this.direction.next(null);
    }
  }

  /**
   * Notification du changement de statut de l'abonnement
   *
   * Attention, si la requete (ex: pas de grroup) renvoi quand même un objet mais avec un statut null
   * @param refService Référence du service dont on souhaite gérer l'abonnement
   * @param statut Statut de l'abonnement (True = Abonné, False = Désabonné)
   */
  changerAbonnement(refService: string, statut: boolean) {
    let groupsExiste = false;
    this.parametreUtilisateurService.ajouterRetirerLeServiceEnAbonnement(refService, statut)
      .subscribe(
        (b) => {
          this.refServiceAbonnement.next({ refService: refService, statut: statut });
          groupsExiste = true;
        },
        (e) => { },
        // Pour notifier la fin du chargement même quand pas d'abo
        () => {
          if (!groupsExiste) {
            this.refServiceAbonnement.next({ refService: refService, statut: null });
          }
        }
      );
  }

  /**
   * Notification du changement de statut du favoris
   * @param refService Référence du service dont on souhaite gérer le statut de favoris
   * @param statut Statut de favoris (True = Passage du service en favoris, False = Supprime le statut de favoris du service)
   */
  changerFavoris(refService: string, statut: boolean) {
    this.parametreUtilisateurService.ajouterRetirerLeServiceEnFavoris(refService, statut)
      .subscribe((b) => this.refServiceFavoris.next(refService));
  }

  /**
   * Notification de la création d'une nouvelle indisponibilité
   * @param indisponibiliteDto IndisponibiliteDto de l'indispo créée
   */
  changerNouvelleIndispo(indisponibiliteDto: IndisponibiliteDto) {
    this.indisponibilite.next(indisponibiliteDto);
  }

  /**
   * Notification de la sélection d'une date dans le calendrier de l'écran mon bulletin météo
   * @param date Date sélectionné
   */
  changerDate(date: Date) {
    this.dateCalendrier.next(date);
  }

  /**
   * Permet d'ajouter une erreur
   * @param message Contenu du snack bar
   * @param typeMessage Couleur de la police
   * @param codeErreur Optionnel - Ajout d'un code erreur
  */
  ajouterMessageSnackBar(message: string, typeMessage: TYPE_MESSAGE, codeErreur?: number) {
    this.erreurs.next({ message, codeErreur, typeMessage });
  }

  /**
   * Permet de récupèrer l'utilisateur en cours (Avec possibilité de souscrire aux notifications)
   */
  get utilisateurEnCours(): Observable<Utilisateur> {
    return this.utilisateur.asObservable().pipe(filter(u => !!u));
  }

  /**
   * Permet de récupèrer la direction sélectionnée dans l'écran mon bulletin météo (Avec possibilité de souscrire aux notifications)
   */
  get directionEnCours(): Observable<Direction> {
    return this.direction.asObservable().pipe(filter(d => !!d));
  }

  /**
   * Permet de récupèrer l'IndisponibiliteDto de l'indispo créée (Avec possibilité de souscrire aux notifications)
   */
  get nouvelleIndispo(): Observable<IndisponibiliteDto> {
    return this.indisponibilite.asObservable().pipe(filter(i => !!i));
  }

  /**
   * Permet de récupèrer une erreur survenue (Avec possibilité de souscrire aux notifications)
   */
  get erreurApplication(): Observable<{ message: string, codeErreur: number, typeMessage: TYPE_MESSAGE }> {
    return this.erreurs.asObservable().pipe(filter(e => !!e), debounceTime(400));
  }

  /**
   * Permet de récupèrer la date sélectionné dans le calendrier de l'écran mon bulletin météo
   * (Avec possibilité de souscrire aux notifications)
   */
  get dateCalendrierSelectionnee(): Observable<Date> {
    return this.dateCalendrier.asObservable().pipe(filter(d => !!d));
  }

  /**
   * Permet de récupèrer la référence du service dont l'état de l'abonnement a été modifié
   * (Avec possibilité de souscrire aux notifications)
   * Debounce pour ne pas notifier à chaque changement d'état lors du bouton tout cocher
   */
  get refServiceChangementAbonnement(): Observable<{ refService: string, statut: boolean }> {
    return this.refServiceAbonnement.asObservable().pipe(filter(d => !!d));
  }

  /**
   * Permet de récupèrer la référence du service dont l'état de favoris a été modifié
   * (Avec possibilité de souscrire aux notifications)
   * Debounce pour ne pas notifier à chaque changement d'état lors du bouton tout cocher
   */
  get refServiceChangementFavoris(): Observable<string> {
    return this.refServiceFavoris.asObservable().pipe(filter(d => !!d), debounceTime(80));
  }

}
