import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-bouton-tuto",
  templateUrl: "./bouton-tuto.component.html",
  styleUrls: ["./bouton-tuto.component.scss"],
})
export class BoutonTutoComponent {
  public url: string;
  constructor(private dialog: MatDialog) {}
  ngOnInit() {
    this.url =
      "https://docs.google.com/presentation/d/e/2PACX-1vTbR9-0xx3zBDgPn_8LfjVfVA7WAUxgzIKi18SArGIwuAFMzcpkeCgKGj8R4rVRDPsrl4_OUwWpwfDS/pub?start=true&loop=true&delayms=10000&slide=id.p1";
  }
}
